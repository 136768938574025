// Desc: Functions for generating report title based on parameters
const generateReportTitle = ({batch, shedType, date, lastDate, reportName}) => {
   const formattedBatchName = batch?.name ? `${batch.name}-` : "";
   const shedTypeName = shedType?.shed_type && shedType.shed_type !== "None" ? `${shedType.shed_type}-` : "";
   const startDate = date ? (date.getDate()) +"-"+(date.getMonth()+1) + "-" + date.getFullYear() + "-" : "";
   const endDate = lastDate ? "to-" +(lastDate.getDate()) +"-"+(lastDate.getMonth()+1) + "-" + lastDate.getFullYear() + "-" : "";

   const title = `${formattedBatchName}${shedTypeName}${startDate}${endDate}${reportName}_Report`;

   return title
}

export { generateReportTitle }