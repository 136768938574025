import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import moment from 'moment';
import FeedFormulation from "./components/feedFormulation";
import Icon from '@material-ui/core/Icon';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PetsIcon from '@material-ui/icons/Pets';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CalculateFeedFormulation from "./components/calculateFeedFormulation";
import MaterialAndMedicineDialog from './components/MaterialAndMedicineDialog';
import EnergyProfileDisplay from './components/EnergyProfileDisplay';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    purple: {
        color: theme.palette.primary.main
    },
    red: {
        color: '#f44336'
    },
    green:{
        color:'#4caf50'
    },
    button: {
        marginLeft: theme.spacing(2),
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            marginBottom: theme.spacing(1)
          }
    },
}));

let currentFeedFormulation = undefined;
let editMode = false;
let action = 'New';
let feedFormulationOldID = 0;
const FeedFormulationsView = ({ showError, showMessage, history }) => {

    const [feedFormulations, setFeedFormulations] = useState([]);
    const [farms, setFarms] = useState([]);
    const [sheds, setShed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showFeedFormulationDialog, setShowFeedFormulationDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [farmShedHash, setFarmShedHash] = useState([]);
    const [showCalculateFeedFormulationDialog, setShowCalculateFeedFormulationDialog] = useState(false);
    
    const [showAllFormulationDialog,setShowAllFormulationDialog] = useState(false);
    const [selectedFeedFormulation,setSelectedFormulation] = useState({});
    const [feedMaterialsOnly,setFeedMaterials] = useState([]);
    const [medicineData,setMedicineData]       = useState([]);
    const [storageFormula, setStorageFormula] = useState([]);
    const [currentTable, setCurrentTable] = useState('both'); // store which table want to show in all formulation popup

    //This has to be a JSON - Since the API returns a Hashmap
    const [energies, setEnergies] = useState({});
    const [materials, setMaterials] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const theme = useTheme();
    const [storeSearch, setStoreSearch] = useState(window.localStorage.getItem('feed-formulation'))

    // this state will update when material data is updated in `MaterialAndMedicineDialog`
    // using this state change, we can update energy profile from `EnergyProfileDisplay` component
    const [updatedFeedFormulationData, setUpdatedFeedFormulationData] = useState({feedFormulationId: null, materialData: [], medicineData: []});

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewFeedFormulation(event) {
        action = 'New';
        editMode = false;
        event.preventDefault();
        setShowFeedFormulationDialog(true);
    }

    function onCalculateFeedFormulation(event) {
        action = 'Calculate';
        event.preventDefault();
        setShowCalculateFeedFormulationDialog(true);
    }

    function handleFeedFormulationClose() {
        currentFeedFormulation = undefined;
        setErrorMessage(null);
        setShed([])
        setShowFeedFormulationDialog(false);
    }

    function handleAllFormulationClose(){
        setFeedMaterials([])
        setMedicineData([])
        setSelectedFormulation({})
        showError("")
        setShowAllFormulationDialog(false);
    }

    function handleCalculateFeedFormulationClose() {
        currentFeedFormulation = undefined;
        setErrorMessage(null);
        setShed([])
        setShowCalculateFeedFormulationDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const resetUpdatedFeedFormulationData = () => {
        if(updatedFeedFormulationData.feedFormulationId !== null){
            setUpdatedFeedFormulationData({feedFormulationId: null, materialData: [], medicineData: []});
        }
    }

    const searchMaxDate = (data) => {
        let hashMap = {}
        let elementID;
        data.forEach(element => {
            if (element.isStorage) {
                elementID = "storage_" + element.feedStorageID 
            } else {
                elementID = element.farmID + "_" + element.shedID
            }
            if (hashMap[elementID] === undefined) {
                let dateArray;
                if (element.isStorage) {
                    dateArray = data.filter(dateArrayElement =>
                        (dateArrayElement.feedStorageID === element.feedStorageID)
                    )
                } else {
                    dateArray = data.filter(dateArrayElement =>
                        (dateArrayElement.farmID === element.farmID && dateArrayElement.shedID === element.shedID)
                    )
                }
                let moments = dateArray.map(element => { return (moment(element.date)) })
                hashMap = {
                    ...hashMap,
                    [elementID]: moment.max(moments)
                }
            }
        })
        console.debug("the date array is ", hashMap)
        setFarmShedHash(hashMap)
    }

    const onFarmDeleteConfirm = () => {
        if (currentFeedFormulation && currentFeedFormulation.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.delete(`feed-formulation/${currentFeedFormulation.id}`);

            response.then(res => {
                if (res) {
                    let index = feedFormulations.findIndex((srcFeedFormulation) => {
                        return srcFeedFormulation.id === currentFeedFormulation.id;
                    });
                    let newFeedFormulations = [...feedFormulations];
                    newFeedFormulations.splice(index, 1);
                    setFeedFormulations(newFeedFormulations);
                    searchMaxDate(newFeedFormulations)
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('FeedFormulation not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentFeedFormulation = undefined;
            });

        }
    };

    const onDelete = (feedFormulation) => {
        currentFeedFormulation = feedFormulation;
        setShowDeleteConfirm(true);
    };

    const onCopy = (event, feedFormulation) => {
        console.debug("copy feedFormulation ", feedFormulation)
        action = 'Copy';
        feedFormulationOldID = feedFormulation.id
        editMode = false;
        event.preventDefault();
        setShowFeedFormulationDialog(true);
    };


    const onFeedFormulationSave = (feedFormulation, setpopuploading) => {
        //setLoading(true);
        let url = 'feed-formulation'
        console.debug("before copy ", action, url, feedFormulation)
        if (action === 'Copy' && feedFormulationOldID !== 0) {
            feedFormulation = {
                ...feedFormulation,
                feedFormulationOldID: feedFormulationOldID
            }
            url = 'feed-formulation-copy'
            console.debug("copy ", action, url, feedFormulation)
        }
        const response = api.post(url, {
            ...feedFormulation
        });

        response.then(res => {
            setFeedFormulations([...feedFormulations, res]);
            searchMaxDate([...feedFormulations, res])
            setShowFeedFormulationDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('FeedFormulation not added');
                }
                else if (err.message === 'duplicate key') {
                    setErrorMessage('Duplicate Entry');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
            action = 'New'
            feedFormulationOldID = 0
        });
    };


    const onFeedFormulationEdit = (feedFormulation) => {
        getAllSheds(feedFormulation.farmID)
        console.debug("in edit ", feedFormulation, sheds)
        currentFeedFormulation = {
            ...feedFormulation,
            farmIndex: farms.findIndex((farm) => { return farm.id === feedFormulation.farmID; }),
            shedIndex: sheds.findIndex((shed) => { return shed.id === feedFormulation.shedID; }),
        };
        editMode = true;
        action = 'Update';
        console.debug("in edit ", feedFormulation, sheds)
        setShowFeedFormulationDialog(true);

    };

    const onFeedFormulationUpdate = (feedFormulation, setpopuploading) => {
        //setLoading(true);
        showError('');
        const response = api.post(`feed-formulation/${feedFormulation.id}`, {
            ...feedFormulation
        });
        response.then(() => {
            let index = feedFormulations.findIndex((srcFeedFormulation) => {
                return srcFeedFormulation.id === feedFormulation.id;
            });
            let newFeedFormulation = [...feedFormulations.slice(0, index),
            { ...feedFormulation },
            ...feedFormulations.slice(index + 1)]

            setFeedFormulations(newFeedFormulation);
            searchMaxDate(newFeedFormulation)
            setShowFeedFormulationDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('FeedFormulation not Updated');
                else if (err.message === 'duplicate key')
                    setErrorMessage('Duplicate Entry');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
            action = 'New'
        });
    };

    //Get all FeedFormulations API Call
    const getAllFeedFormulations = () => {
        setShowFeedFormulationDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('feed-formulation');

        response.then(res => {
            setFeedFormulations([...res]);
            searchMaxDate(res)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Feed Formulations Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const getAllFarms = () => {
        setLoading(true);
        showError('');
        const response = api.get('farm');
        response.then(res => {
            setFarms([...res]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Farms Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        return response;
    };

    const getAllStorageFormula = () => {
        setLoading(true);
        showError('');
        const response = api.post('/available-feed-formulation-storage',{});
        response.then(res => {
            setStorageFormula([...res]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log("No storage Formula Found")
            } else {
                showError('Unknown Error')
            }
        });
        return response;
    };


    //Get all Shed API Call
    const getAllSheds = (farmID) => {
        setShed([])
        //setLoading(true);
        // showError('');
        showMessage('');
        console.log('feed farm ', farmID)
        const response = api.get(`farm/${farmID}/shed`);
        response.then(res => {
            setShed([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Sheds');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const getEnergies = () => {
        setLoading(true);
        showError('');
        const response = api.get('energies');
        response.then(res => {
            //This has to be a JSON - Since the API returns a Hashmap
            setEnergies({...res});
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Energies Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        return response;
    };

    const createCalculatedFeedFormulation = (data) => {
        setLoading(true);
        // showError('');
        showMessage('');
        let url = 'feed-formulation-create'
            console.debug("before copy ",action,url,data)
        const response = api.post(url, {
            ...data
        });

        response.then(res => {
            setFeedFormulations([...feedFormulations, res]);
            searchMaxDate([...feedFormulations, res])
            setShowCalculateFeedFormulationDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('FeedFormulation not added');
                else if (err.message === 'duplicate key')
                setErrorMessage('Duplicate Entry');
                else
                setErrorMessage(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            action = 'New'
            feedFormulationOldID = 0
        });
    };

    const getAllMaterials = () => {
        const response = api.get('materials-medicines');
        response.then(res => {
            setMaterials([...res]);
            /*materialMap = res.reduce(function (acc, cur, i) {
                console.debug("materialTypeID",cur.materialTypeID)
                acc[cur.id] = cur.materialName;
                return acc;
            }, {});*/
            console.debug("rasdes", res)
            //console.debug("materialMap", materialMap)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    alert(err.message)
            } else {
                alert('Unknown Error')
            }
        })
        return response
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllFeedFormulations(), getAllFarms(), getEnergies(),getAllStorageFormula()], getAllMaterials()).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);

    useEffect(() => {
        window.onbeforeunload = () => {
            localStorage.removeItem('feed-formulation');
          }
    })

    // useEffect(getAllFeedFormulations, []);

    function openFeedFormulationMaterial(event, rowData) {
        setCurrentTable('material')
        showFeedFormulationAll(event, rowData)
    }

    function openFeedFormulationMedicine(event, rowData) {
        setCurrentTable('medicine')
        showFeedFormulationAll(event, rowData)
    }

    function openFeedFormulationWithBothTable(event, rowData) {
        setCurrentTable('both')
        showFeedFormulationAll(event, rowData)
    }

  async function showFeedFormulationAll(event,rowData){
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);


        const payLoad = {
            dailyFeedProductionID : rowData.id,
            dailyFeedProductionShedID : rowData.shedID,
            dailyFeedProductionQuantity : rowData.quantity,
            dailyFeedProductionDate : rowData.date,
            dailyFeedProductionCreatedDate : rowData.createTime,
        }
        const feedResponse =  api.post('get-feed-materials',payLoad);
        const medResponse  =  api.get(`feed-formulation/${rowData.id}/feed-formulation-medicine`);
        const matResponse  =  api.get(`feed-formulation/${rowData.id}/feed-formulation-material`);

        await feedResponse.then((values)=>{
            var tFeedFormulation = {...feedFormulations[rowData.tableData.id]};
            tFeedFormulation['date'] = moment(tFeedFormulation['date']).format("DD-MM-YYYY");
            tFeedFormulation['isActive'] =  ((moment(rowData.date) >= farmShedHash[rowData.farmID + "_" + rowData.shedID]) || (moment(rowData.date) >= farmShedHash["storage_" +rowData.feedStorageID])) ? 'Active': 'Inactive';
            setSelectedFormulation(tFeedFormulation);
             
        }).catch((err)=>{
             if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Feed Data Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
         //Feed Medicine Response
        await medResponse.then((values)=>{
            if(values !== null && values !== undefined && values.length){
                setMedicineData(values);
            }
             
        }).catch((err)=>{
             if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Medicine Data Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })

        //Feed Material Response
        await matResponse.then((values)=>{
             if(values !== null && values !== undefined && values.length){
             setFeedMaterials(values);
            }
        }).catch((err)=>{
             if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Feed Material Data Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        setLoading(false)
        setShowAllFormulationDialog(true);
    }

    let feedFormulationNameColumn = {
        title: 'Feed Formulations', field: 'date', defaultSort: 'desc',
        render: rowData => <Mobile data={rowData}
            farmShedHash={farmShedHash}
            onEdit={() => {
                onFeedFormulationEdit(rowData)
            }}
            onDelete={() => {
                onDelete(rowData)
            }}
            onCopy={(event) => {
                onCopy(event, rowData)
            }}
            openFeedFormulationMaterial={(event) => {
                openFeedFormulationMaterial(event, rowData);
            }}

            openFeedFormulationMedicine={(event) => {
                openFeedFormulationMedicine(event, rowData);
            }}
        />
    };
    let actions = [];
    if (isDesktop) {
        feedFormulationNameColumn = {
            title: 'Date', field: 'date', defaultSort: 'desc',
            type: 'datetime',
            editable: 'never',
            hidden: !isDesktop,
            render: rowData => {
                return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
            }
        };
        actions = [
            /*{
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Feed Formulation',
                onClick: (event, rowData) => onFeedFormulationEdit(rowData)
            },*/
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Feed Formulation',
                onClick: (event, rowData) => onDelete(rowData)
            },
            {
                icon: () => <FileCopyIcon className={classes.purple} />,
                tooltip: 'Copy Feed Formulation',
                onClick: (event, rowData) => onCopy(event, rowData)
            }
        ]
    }
    
    return <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Link color="inherit" href="#" onClick={() => history.push('/')} className={classes.link}>
                <HomeIcon className={classes.icon} />
                Home
            </Link>
            <Typography color="textPrimary" className={classes.link}>
                <PetsIcon className={classes.icon} />
                Feed Formulations
            </Typography>
        </Breadcrumbs>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Feed Formulations Overview
                </Typography>
            </Grid>
            <Grid
                container
                justify={isDesktop?'flex-end':'center'}
                direction="row"
                alignItems='flex-end'
            >
                <Grid className={classes.button}>
                    <Button variant="contained" color="primary" onClick={onNewFeedFormulation}>Add Feed Formulation</Button>
                </Grid>
                <Grid className={classes.button}>
                    <Button variant="contained" color="secondary" onClick={onCalculateFeedFormulation}>Calculate Feed Formulation</Button>
                </Grid>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            onSearchChange={(e) => {
                setStoreSearch(localStorage.setItem("feed-formulation",e))
              }}

            columns={[
                { title: 'id', field: 'id', hidden: true },
                feedFormulationNameColumn,
                {
                    title: 'Active', field: 'isActive', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return (
                            ((moment(rowData.date) >= farmShedHash[rowData.farmID + "_" + rowData.shedID]) || (moment(rowData.date) >= farmShedHash["storage_" +rowData.feedStorageID]) )?
                                <FiberManualRecordIcon className={classes.green} />
                                :
                                <FiberManualRecordIcon className={classes.red} />
                        )
                    },
                    cellStyle: {
                        padding: '0px',
                    },
                      headerStyle: {
                        padding: '0px',
                        alignItems: 'center'
                    }
                },
                { title: 'Farm / Storage', field: 'farmName', hidden: !isDesktop, editable: 'never',
                render: rowData => {
                    return (
                        rowData.isStorage ? `${rowData.feedStorageName} (Storage)` : rowData.farmName
                    )
                }

                },
                { title: 'Shed', field: 'shedName', hidden: !isDesktop, editable: 'never' },
                {
                    title: 'More',
                    field: 'id',
                    editable: 'never',
                    hidden: !isDesktop,
                    render: rowData => {
                        return <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                            <Button onClick={(e) => {
                                openFeedFormulationMaterial(e, rowData)
                            }} size="small" color="primary">Materials</Button>
                            <Button onClick={(e) => {
                                openFeedFormulationMedicine(e, rowData)
                            }} size="small" color="secondary">Medicines</Button>
                        </ButtonGroup>
                    }
                },
                {
                    title: 'Energy',
                    field: 'energy',
                    editable: 'never',
                    hidden: !isDesktop,
                    render: rowData => {
                        return <EnergyProfileDisplay 
                                    rowData={rowData} 
                                    materialNutritionMap={energies} 
                                    updatedFeedFormulationData={updatedFeedFormulationData} 
                                />
                    }
                },
            ]}
            title='Feed Formulations Table'
            data={feedFormulations}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                searchText:storeSearch,
                toolbar: true
            }}
            actions={actions}
            onRowClick={isDesktop ? openFeedFormulationWithBothTable : undefined}
            // if page is changed, reset updatedFeedFormulationData(used to get energy profile of the new rows)
            onChangePage={resetUpdatedFeedFormulationData}
            // if number of rows per page is changed, reset updatedFeedFormulationData(used to get energy profile of the new rows)
            onChangeRowsPerPage={resetUpdatedFeedFormulationData}
        />
        <FeedFormulation deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showFeedFormulationDialog} handleClose={handleFeedFormulationClose} onSave={onFeedFormulationSave} onEdit={onFeedFormulationUpdate}
            editMode={editMode} data={currentFeedFormulation} sheds={sheds} farms={farms} onFarmChange={getAllSheds} storageformula={storageFormula} action={action} />

        <MaterialAndMedicineDialog  showDialog={showAllFormulationDialog} handleClose={handleAllFormulationClose} 
            showError={showError} showMessage={showMessage} feedData={feedMaterialsOnly} setFeedData={setFeedMaterials} medicineData={medicineData} setMedicineData={setMedicineData} feedFormulation={selectedFeedFormulation} currentTable={currentTable} setCurrentTable={setCurrentTable} 
            energies={energies} setUpdatedFeedFormulationData={setUpdatedFeedFormulationData} 
        />

        <CalculateFeedFormulation deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showCalculateFeedFormulationDialog} handleClose={handleCalculateFeedFormulationClose}
            //onSave={onFeedFormulationSave} onEdit={onFeedFormulationUpdate} editMode={editMode} data={currentFeedFormulation} 
            createCalculatedFeedFormulation={createCalculatedFeedFormulation} energies={energies} materials={materials}
            sheds={sheds} farms={farms} onFarmChange={getAllSheds} action={action} />

        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onFarmDeleteConfirm}
            title='Delete Feed Formulation ?'
            subText='This action will delete the Feed Formulation and all Materials and Medicines associated with it.Please confirm.' />
    </div>
}

export default FeedFormulationsView;