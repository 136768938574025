import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    gridMargin: {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2)
    },
    activeFlag: {
        background:"green",
        color:"white",
        borderRadius:"20px",
        padding:"0px 20px",
        fontWeight:"700",
        fontSize:"10px",
        marginLeft:"14px"
    },
    typeLabel:{
        borderRadius:"20px",
        padding:"3px 10px",
        fontWeight:"700",
        fontSize:"10px",
        marginTop:"5px"
    },
    typeLabelAvtive:{
        background:"#F0E8FE",
        borderRadius:"20px",
        padding:"3px 10px",
        fontWeight:"700",
        marginTop:"5px",
        display:"flex",
        fontSize:"10px",
        justifyContent:"space-around",
        alignItems:"center",

    }
}));

const Mobile = ({ data, onEdit }) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };


    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                title={`${data.batchName}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='center'
                    alignItems='flex-end'
                >
                    {
                        data.chickName !== "" ?
                                <Grid item class={classes.gridMargin}>
                                    <div style={{ textAlign: "center", width: "100%",fontWeight: "600" }}>
                                        <div>
                                            {data.chickName}
                                        </div>
                                        <div >
                                            <div  style={{background:data.chickName !== "" ? "#E8F7F4" :""}}
                                                className={data.chickActive ? classes.typeLabelAvtive :classes.typeLabel}>
                                                                <div>{data.ChickDateRange}</div>
                                                                {
                                                                    data.chickActive ? 
                                                                        <div className={classes.activeFlag}>Active</div>  : ""
                                                                }
                                                </div>
                                        </div>
                                    </div>
                                </Grid>
                                : ""
                    }
                        {
                            data.growerName !== "" ?
                              <Grid class={classes.gridMargin} item>

                                    <div style={{ textAlign: "center", width: "100%",fontWeight: "600" }}>
                                                <div>
                                                    {data.growerName}
                                                </div>
                                                <div >
                                                    <div  style={{background:data.growerName !== "" ? "#FFF4C9" :""}}
                                                    className={data.growerActive ? classes.typeLabelAvtive :classes.typeLabel}>
                                                                    <div>{data.GrowerDateRange}</div>
                                                                    {
                                                                        data.growerActive ? 
                                                                            <div className={classes.activeFlag}>Active</div>  : ""
                                                                    }
                                                    </div>
                                                
                                                </div>
                                     </div> 
                              </Grid> : ""
                        }
                        {data.layerName ? 
                        
                            <Grid class={classes.gridMargin} item>
                                    <div style={{ textAlign: "center", width: "100%",fontWeight: "600" }}>
                                                <div>
                                                    {data.layerName}
                                                </div>
                                                <div >
                                                <div  style={{background:data.layerName !== "" ? "#F0E8FE" :""}}
                                                className={data.layerActive ? classes.typeLabelAvtive :classes.typeLabel}>
                                                                    <div> {data.LayerDateRange}</div>
                                                                    {
                                                                        data.layerActive ? 
                                                                            <div className={classes.activeFlag}>Active</div>  : ""
                                                                    }
                                                </div>
                                                
                                                </div>
                                        </div>
                            </Grid> :""
                        }
                </Grid>
            </CardContent>
        </Card>
        <Menu
            id="shed-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;