import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Chip, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../common/api';
import { calculateEnergyData, formatEnergyProfileValue, getTotalQuantity } from '../utils/nutritionCalculations';
import { minChipLabel } from '../utils/constants';

const EnergyProfileDisplay = ({ rowData, materialNutritionMap, updatedFeedFormulationData }) => {
    const initialEnergyValues = {
        metabolizableEnergy: {
            name: "Metabolizable Energy",
            shortName:'ME',
            value: 0
        },
        protein: {
            name: "Protein",
            shortName: "Pr",
            value: 0
        },
        fat: {
            name: "Fat",
            shortName: "Fa",
            value: 0
        },
        fibre: {
            name: "Fibre",
            shortName: "Fi",
            value: 0
        },
        calcium: {
            name: "Calcium",
            shortName: "Ca",
            value: 0
        },
        phosphorus: {
            name: "Phosphorus",
            shortName: "P",
            value: 0
        },
        lysine: {
            name: "Lysine",
            shortName: "Ly",
            value: 0
        },
        methionine: {
            name: "Methionine",
            shortName: "Me",
            value: 0
        }
    }

   const [energyValues, setEnergyValues] = useState(initialEnergyValues)
   const [showEnergyProfile, setShowEnergyProfile] = useState(false)
   const [materials, setMaterials] = useState([])
   const [medicines, setMedicines] = useState([])

   let totalQuantity = getTotalQuantity(materials) + getTotalQuantity(medicines);

   const materialsRef = useRef();
   const medicineRef = useRef();

   useEffect(() => {
    materialsRef.current = materials;
}, [materials]);

   useEffect(() => {
    medicineRef.current = medicines;
}, [medicines]);
   
   const getMaterialData = useCallback(() => {
    api.get(`feed-formulation/${rowData.id}/feed-formulation-material`)
        .then(materials => { 
            if (materials !== null && materials !== undefined && materials.length) {
                setMaterials(materials);
            }
        })
        .catch(err => {
            console.error(err);
        });
}, [rowData.id, setMaterials]);

    const getMedicineData = useCallback(() => {
        api.get(`feed-formulation/${rowData.id}/feed-formulation-medicine`)
            .then(medicines => {
                if (medicines !== null && medicines !== undefined && medicines.length) {
                    setMedicines(medicines);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }, [rowData.id, setMedicines]);

   const setEnergyData = () => {
      if(materials.length === 0){
          setEnergyValues(initialEnergyValues);
          return;
      }
      setEnergyValues(calculateEnergyData({ data: materials, energyValues, materialNutritionMap }));
  
      // checking all energyValues are zero
      // calculate total energy, if it is not zero then show 3 items
      const totalEnergy = Object.keys(energyValues).reduce((accumulator, currentKey) => {
          return accumulator + energyValues[currentKey].value;
      }, 0);
      if (totalEnergy > 0) {
         setShowEnergyProfile(true);
      }else{
         setShowEnergyProfile(false);
      }
   }
  
   useEffect(setEnergyData, [materials, medicines]);

   useEffect(() => {
        // if this is first render(initial value of feedFormulationId is null), then get material and medicine data
        if (updatedFeedFormulationData.feedFormulationId === null) {
            getMaterialData();
            getMedicineData();
        }

   }, [getMaterialData, getMedicineData, updatedFeedFormulationData]);
    
    useEffect(()=>{
        const { feedFormulationId, materialData, medicineData } = updatedFeedFormulationData;

        // if updated material data belongs to this component, then get new data
        if(feedFormulationId === rowData.id){
            // if updated data is materials, get new material data
            if (materialData !== null && materialData !== materialsRef.current){
                getMaterialData();
            // if updated data is medicines, get new medicine data
            }else if (medicineData !== null && medicineData !== medicineRef.current){
                    getMedicineData();
            }
        }


    }, [rowData, updatedFeedFormulationData, getMaterialData, getMedicineData]);

   const EnergyTooltip = withStyles((theme) => ({
       tooltip: {
           backgroundColor: '#f7f7f8',
           padding: theme.spacing(2),
           // add some box shadow, there is no shadow in 'theme'
           boxShadow: `0px 0px 2px 0px ${theme.palette.primary.main}`,
       },
       arrow: {
           '&::before': {
               color: '#f7f7f8',
               boxShadow: `0px 0px 2px 0px ${theme.palette.primary.main}`,
           }
       },
   }))(Tooltip);

   return (
       <Grid container spacing={1} justifyContent='space-between' wrap='nowrap'>
         {showEnergyProfile ?
            <>
            {Object.keys(energyValues).map((key, index) => {
               if(index < minChipLabel){
                   return (
                       <Grid item key={index}>
                           <Chip 
                               size="small"
                               color="primary"
                               label={`${energyValues[key].shortName}: ${formatEnergyProfileValue(energyValues[key].value, totalQuantity)}`} 
                           />
                       </Grid>
                   )
               }
               return null;
           })}
           <EnergyTooltip 
               arrow={true}
               title = {
                   <Grid container spacing={1}>
                       {Object.keys(energyValues).map((key, index) => {
                           return (
                               <Grid item key={index}>
                                   <Chip 
                                       size="small"
                                       color="primary"
                                       label={`${energyValues[key].name}: ${formatEnergyProfileValue(energyValues[key].value, totalQuantity)}`} 
                                   />
                               </Grid>
                           )
                       })}
                   </Grid>
               }
           >
               <Grid item>
                   <Chip size="small" color="primary" label={`+ ${Object.keys(energyValues).length - minChipLabel}`} />
               </Grid>
           </EnergyTooltip></>
         
         : <Typography variant="body1" color="textPrimary">No Energy Data</Typography>
         }

       </Grid>
   );
};

export default EnergyProfileDisplay;