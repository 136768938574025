import React, {useCallback, useEffect, useState} from 'react';
import api from '../../common/api';
import { getAllTaxes } from '../../common/functions';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog, MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import Mobile from './components/mobile'
import moment from 'moment';
import Purchase from "./components/purchase";
//import Icon from '@material-ui/core/Icon';
import PaymentIcon from '@material-ui/icons/Payment';
import PurchasePaymentDetails from "./components/purchasePaymentDetails";
import PurchaseDetails from './components/purchaseDetails'
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Icon from "@material-ui/core/Icon";
import GetAppIcon from '@material-ui/icons/GetApp';
import {useGlobalState} from '../../state';

import  PurchaseMaterialTable  from './components/purchaseMaterial';
import { version } from '../../common/enums';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    red:{
        color:'red'
    },
    green:{
        color: 'green'
    },
    gridMargin:{
        margin:theme.spacing(4)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    searchButton:{
        color:'white',
        width:theme.spacing(21.25),
        backgroundColor: theme.palette.red.dark,
        '&:hover':{
            backgroundColor:theme.palette.red.lightDark,
        },
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    },
    success: {
        color:'white',
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
        width:theme.spacing(13.75),
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    }
}));
let currentPurchase = undefined;
let editMode = false;
const PurchasesView = ({showError, showMessage}) => {
    const [currentPurchasePayment, setCurrentPurchasePayment] = useState({});
    const [purchases, setPurchases] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [commissionAgents, setCommissionAgents] = useState([]);
    const [farms, setFarms] = useState([]);
    const [sheds, setShed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEditConfirm, setShowEditConfirm] = useState(false);
    const [showPurchasePaymentDialog, setShowPurchasePaymentDialog] = useState(false);
    const [banks, setBanks] = useState([]);
    const [showPurchaseDetailsDialog, setShowPurchaseDetailsDialog] = useState(false);
    const theme = useTheme();

    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [filterSelectedVendorId, setFilterSelectedVendorId] = useState('');
    const [filterSelectedPaymentStatus, setFilterSelectedPaymentStatus] = useState('');
    const [selectedVendorId, setSelectedVendorId] = useState('');
    const [doSearch, setDoSearch] = useState(false);
    const [materialTypes, setMaterialTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const [resetBillTotal, setResetBillTotal] = useState(false)

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const [{user}] = useGlobalState();
    console.log("user------>", user)
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    let csvHeader=[]
    if(user.versionID===version.pro){
        csvHeader = ["Date","Vendor Name","Material Type","Item Name","WeightType","Bill Weight",
        "Delivery Weight","Cost Per Unit","Bill Value","Payment Due(In Days)","Bill Value","Payment Status","Balance"]
    }
    else{
        csvHeader = ["Date","Vendor Name","Material Type","Item Name","Inventory Type","WeightType","Bill Weight",
        "Delivery Weight","Cost Per Unit","Bill Value","Payment Due(In Days)","Bill Value","Payment Status","Balance"]
    }


    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const handleFilterVendorChange = (event) => {
        setSelectedVendorId(event.target.value);
        setFilterSelectedVendorId(vendors[event.target.value].id);
    };

    const handleFilterPaymentStatus = (event) => {
        setFilterSelectedPaymentStatus(event.target.value);
    };

    function onNewPurchase(event) {
        editMode = false;
        event.preventDefault();
        setShowPurchaseDialog(true);
    }

    function handlePurchaseClose() {
        currentPurchase = undefined;
        setShowPurchaseDialog(false);
        setResetBillTotal(false)
        setShowEditConfirm(false);
        if(errorMessage){
            removeErrorMessage();
        }
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    function handlePurchasePaymentClose() {
        currentPurchase = undefined;
        setShowPurchasePaymentDialog(false);
    }

    function handlePurchaseDetailsDialogClose() {
        currentPurchase = undefined;
        setShowPurchaseDetailsDialog(false);
        setShowEditConfirm(false);
    }

    function openPurchasesDetailsDialog (event,rowData) {
        currentPurchase = rowData;
        setShowPurchaseDetailsDialog(true);
    }

    const onPurchaseDeleteConfirm = () => {
        if (currentPurchase && currentPurchase.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`purchase/${currentPurchase.id}`);

            response.then(res => {
                if (res) {
                    let index = purchases.findIndex((srcPurchase) => {
                        return srcPurchase.id === currentPurchase.id;
                    });
                    let newPurchases = [...purchases];
                    newPurchases.splice(index, 1);
                    setPurchases(newPurchases);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Purchase not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentPurchase = undefined;
            });

        }
    };

    const onDelete = (purchase) => {
        currentPurchase = purchase;
        if(user.versionID === version.pro){
            currentPurchase.id = purchase.purchaseID;
        }
        setShowDeleteConfirm(true);
        setShowPurchaseDetailsDialog(false)
    };
    
    const onPurchaseSave = (purchase, setPopupLoading) => {
        setErrorMessage('')
        setPopupLoading(true);
        const response = api.post('purchase', {
            ...purchase,
        });

        response.then(res => {
            let totalAmount = 0
            if(res.purchasePayments !== undefined && res.purchasePayments !== null){
                res.purchasePayments.forEach((payment,index)=>{
                    if (payment.paymentMode !== -1) { 
                        totalAmount = totalAmount + payment.paymentAmount
                    }
                })
            }

            // soft copy shed ID to old shed ID for new purchase material 
            if(res.purchaseMaterials !== undefined && res.purchaseMaterials !== null){
                res.purchaseMaterials.forEach((material,index)=>{
                    if (material.shedId !== 0) {
                        material.oldShedId = material.shedId
                    }
                    
                })
            }
            let tdsValue = res.totalBillValue * (res.tds / 100)

            if(totalAmount === 0){
                res = {
                    ...res,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 2
                }
            }else if(totalAmount < (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 1
                }
            }
            res = {
                ...res,
                remainingBalance: totalAmount
            }
            setPurchases([...purchases, res]);
            setShowPurchaseDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Purchase not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

   
   const onPurchaseEdit = (purchase) => {
        setShowEditConfirm(true);
        setShed([])
            currentPurchase = {
                ...purchase,
                vendorIndex:vendors.findIndex((vendor)=>{return vendor.id === purchase.vendorId;}),
                materialIndex:materialTypes.findIndex((material)=>{return material.id === purchase.materialId;}),
                CommissionAgentIndex:commissionAgents.findIndex((commissionAgent)=> {return commissionAgent.id === purchase.commissionAgentID })
            };
    };

    const onProPurchaseEdit = (purchase)=>{
        editMode = true;
        currentPurchase = purchase;
        setShowPurchaseDialog(true);
    };

    const onPurchaseEditConform = () => {
         editMode = true;
         setShowPurchaseDetailsDialog(false)
         setShowPurchaseDialog(true);
    }

    const onPaymentEdit = (purchase) => {
       setCurrentPurchasePayment({
            ...purchase
        })
        setShowPurchaseDetailsDialog(false)
        setShowPurchasePaymentDialog(true);
    };

    const onPurchaseUpdate = (purchase, setPopupLoading) => {
        setErrorMessage('')
        setPopupLoading(true);
        const response = api.post(`purchase/${purchase.id}`, {
            ...purchase,

        });
        response.then((res) => {
            let index = purchases.findIndex((srcPurchase) => {
                return srcPurchase.id === res.id;
            });
            let totalAmount = 0
            if(res.purchasePayments !== undefined && res.purchasePayments !== null){
                res.purchasePayments.forEach((payment,index)=>{
                    if (payment.paymentMode !== -1) {
                        totalAmount = totalAmount + payment.paymentAmount
                    }
                    
                })
            }
            // soft copy shed ID to old shed ID for purchase material update
            if(res.purchaseMaterials !== undefined && res.purchaseMaterials !== null){
                res.purchaseMaterials.forEach((material,index)=>{
                    if (material.shedId !== 0) {
                        material.oldShedId = material.shedId
                    }
                    
                })
            }
            let tdsValue = res.totalBillValue * (res.tds / 100)

            if(totalAmount === 0){
                res = {
                    ...res,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 2
                }
            }else if(totalAmount < (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 1
                }
            }
            res = {
                ...res,
                remainingBalance: totalAmount
            }
            setPurchases([...purchases.slice(0, index),
                {...res},
                ...purchases.slice(index + 1)]);
            setShowPurchaseDialog(false);
            setShowEditConfirm(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Purchase not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false);
        });
    };

    const onPurchasePaymentUpdate = (payment, purchase) => {
        //setShowPurchasePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`purchase-payment/${payment.id}`, {
            ...payment,

        });
        response.then(() => {
            let index = purchases.findIndex((srcPurchase) => {
                return srcPurchase.id === purchase.id;
            });
            let totalAmount = 0
            purchase.purchasePayments.forEach((payment,index)=>{
                totalAmount = totalAmount + payment.paymentAmount
            })

            let tdsValue = purchase.totalBillValue * (purchase.tds / 100)

            if(totalAmount === 0){
                purchase = {
                    ...purchase,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (purchase.totalBillValue + tdsValue)){
                purchase = {
                    ...purchase,
                    paymentStatus : 2
                }
            }else if(totalAmount < (purchase.totalBillValue + tdsValue)){
                purchase = {
                    ...purchase,
                    paymentStatus : 1
                }
            }
            purchase = {
                ...purchase,
                remainingBalance: totalAmount
            }
    
            setPurchases([...purchases.slice(0, index),
                {...purchase},
                ...purchases.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Purchase Payment not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onPurchasePaymentSave = (payment) => {
        //setShowPurchasePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`purchase-payment`, {
            ...payment,

        });
        response.then((res) => {
            let tempCurrentPurchase = currentPurchasePayment
           // tempCurrentPurchase.purchasePayments.push(res)
            if( tempCurrentPurchase.purchasePayments !== undefined &&  tempCurrentPurchase.purchasePayments !== null){
                tempCurrentPurchase.purchasePayments = [
                    ...tempCurrentPurchase.purchasePayments,
                    res
                ]
            }else{
                tempCurrentPurchase.purchasePayments = [
                    res
                ]
            }
            setCurrentPurchasePayment({...tempCurrentPurchase})
            let totalAmount = 0
            tempCurrentPurchase.purchasePayments.forEach((payment,index)=>{
            
                totalAmount = totalAmount + payment.paymentAmount
            })
            
            let tdsValue = tempCurrentPurchase.totalBillValue * (tempCurrentPurchase.tds / 100)

            if(totalAmount === 0){
                tempCurrentPurchase = {
                    ...tempCurrentPurchase,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (tempCurrentPurchase.totalBillValue + tdsValue)){
                tempCurrentPurchase = {
                    ...tempCurrentPurchase,
                    paymentStatus : 2
                }
            }else if(totalAmount < (tempCurrentPurchase.totalBillValue + tdsValue)){
                tempCurrentPurchase = {
                    ...tempCurrentPurchase,
                    paymentStatus : 1
                }
            }
            tempCurrentPurchase = {
                ...tempCurrentPurchase,
                remainingBalance: totalAmount
            }

            let index = purchases.findIndex((srcPurchase) => {
                return srcPurchase.id === tempCurrentPurchase.id;
            });
            setPurchases([...purchases.slice(0, index),
                {...tempCurrentPurchase},
                ...purchases.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Purchase not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onPurchasePaymentDelete = (payment, purchase) => {
        if (payment && payment.id) {
            //setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.post(`purchase-payment-delete/${payment.id}`, {
                ...payment,
            });
            response.then(res => {
                if (res) {
                    let index = purchases.findIndex((srcPurchase) => {
                        return srcPurchase.id === purchase.id;
                    });
                    let totalAmount = 0
                    purchase.purchasePayments.forEach((payment,index)=>{
                        totalAmount = totalAmount + payment.paymentAmount
                    })

                    let tdsValue = purchase.totalBillValue * (purchase.tds / 100)
                    if(totalAmount === 0){
                        purchase = {
                            ...purchase,
                            paymentStatus : 0
                        }
                    }else if(totalAmount >= (purchase.totalBillValue + tdsValue)){
                        purchase = {
                            ...purchase,
                            paymentStatus : 2
                        }
                    }else if(totalAmount < (purchase.totalBillValue + tdsValue)){
                        purchase = {
                            ...purchase,
                            paymentStatus : 1
                        }
                    }
                    purchase = {
                        ...purchase,
                        remainingBalance: totalAmount
                    }

                    setPurchases([...purchases.slice(0, index),
                        {...purchase},
                        ...purchases.slice(index + 1)]);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Purchase not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentPurchase = undefined;
            });

        }
    };
    //Get all Purchases API Call
    const getAllPurchases = useCallback(() => {
        if (filterSelectedEndDate !== null && filterSelectedStartDate !== null &&
            filterSelectedEndDate < filterSelectedStartDate) {
            showError('Start Date is Lesser than or Equal to End Date');
            return
        }
        let payload = {
            vendorId: parseInt(filterSelectedVendorId, 10),
            startDate: filterSelectedStartDate,
            endDate: filterSelectedEndDate,
            paymentStatus: parseInt(filterSelectedPaymentStatus, 10)
        };
        setShowPurchaseDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post('purchases',{...payload});

        response.then(res => {
            res.forEach((value)=>{
                let totalAmount = 0
                if(value.purchasePayments!== null){
                    value.purchasePayments.forEach((payment)=>{
                        totalAmount += payment.paymentAmount
                    })
                }
                value.remainingBalance = totalAmount
            })
            setPurchases([...res]);
        }).catch(err => {
            setPurchases([]);
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Purchases Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [filterSelectedEndDate, filterSelectedPaymentStatus, filterSelectedStartDate, filterSelectedVendorId, showError, showMessage]);

        //Get all Banks API Call
    const getAllBanks = () => {
        
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    };

    //Get all vendors API Call
     const getAllVendors = () => {
        
        showError('');
        showMessage('');

        const response = api.get('vendors-list');

        response.then(res => {
            setVendors([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
     };

        //Get all Commission Agent API Call
        const getAllCommissonAgent = () => {
    
        showError('');
        showMessage('');

        const response = api.get('/commission-agent-list');

        response.then(res => {
            setCommissionAgents([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
        };

  //Get all materialTypes API Call
  const getAllMaterialTypes = () => {
    
    showError('');
    showMessage('');

    const response = api.get('material-types');

    response.then(res => {
        setMaterialTypes([...res]);
    }).catch(err => {
        if (err.message) {
            if (err.message === 'Bad Request' || err.message === 'No data found')
                console.log(err.message)
        } else {
            console.log('Unknown Error')
        }
    });

    return response;
}


    //Get all farms API Call
     const getAllFarms = () => {
        showError('');
        showMessage('');

        const response = api.get('farm');

        response.then(res => {
            setFarms([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
     };

     const generateExcel = function (header, data) {
        // console.log("excel ", header, data)
         let csvData = '';
        header.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else
                csvData = csvData + ',' + column
        });
        
        csvData = csvData + '\r\n';
        
         
        data.map((row) => {
        
             let totalPaymentAmount = 0;
             let tempDate;
             let tempPaymentStatus;
             tempDate=moment(row.date).format("DD-MM-YYYY")
            if(row.paymentStatus === 1){
                tempPaymentStatus = "Partially Paid"
            }else if(row.paymentStatus === 2){
                tempPaymentStatus = "Paid"
            }else{
                tempPaymentStatus = "Unpaid"
            }
                if(row.purchasePayments !== null){
                    row.purchasePayments.map((value,index)=>{
                        totalPaymentAmount += parseInt(value.paymentAmount)
                        totalPaymentAmount = row.totalBillValue-totalPaymentAmount
                        return "";
                    })
                }
            row.purchaseMaterials.map((value,index)=>{
                row.itemName = value.materialName
                row.billWeight = value.billWeight
                row.deliveryWeight = value.deliveryWeight
                row.costPerUnit = value.costPerUnit
                row.billValue = value.billValue
                if(row.paymentStatus === 0){
                    row.paymentAmount = row.totalBillValue
                }else if(row.paymentStatus === 2){
                    row.paymentAmount = 0
                }else{
                    row.paymentAmount = totalPaymentAmount
                }
                if(user.versionID!==version.pro){
                    if(value.inventoryType === 1)
                        row.inventoryType = "FeedMill"
                    else
                        row.inventoryType = "Farm"
                }
                if(value.weightType === 1)
                    row.weightType = "Bill Weight"
                else    
                    row.weightType = "Delivery Weight"
                materialTypes.map((material)=>{
                    if(material.id === value.materialTypeId){
                        row.materialType = material.materialType
                    }
                    return "";
                })
                let column=[]

                if(user.versionID===version.pro){
                    column.push(tempDate,row.vendorName,row.materialType,row.itemName,row.weightType, 
                        row.billWeight,row.deliveryWeight,row.costPerUnit,row.billValue,row.paymentDueDate,
                        row.totalBillValue,tempPaymentStatus,row.paymentAmount)
                }
                else{
                    column.push(tempDate,row.vendorName,row.materialType,row.itemName,row.inventoryType,row.weightType, 
                        row.billWeight,row.deliveryWeight,row.costPerUnit,row.billValue,row.paymentDueDate,
                        row.totalBillValue,tempPaymentStatus,row.paymentAmount)
                }
               
                column.forEach((col, index) => {
                    if (index === 0)
                    csvData = csvData + col
    
                    else
                    csvData = csvData + ',' + col
    
                }); 
                csvData = csvData + '\r\n';
    
              
               return "";
            }); 

            return "";

    
            })
           
    
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'report.csv');
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    
    
    }

    

    const clearSearchFilter = () => {
        setFilterSelectedStartDate(undefined);
        setFilterSelectedEndDate(undefined);
        setFilterSelectedVendorId(undefined);
        setSelectedVendorId(undefined);
        setFilterSelectedPaymentStatus(undefined);
        setDoSearch(true);
    };

    useEffect(() => {
        //doSearch flag is set when search button is clicked.
        if (doSearch) {
            getAllPurchases();
            setDoSearch(false);
        }

    }, [doSearch, getAllPurchases]);

    const onload = () => {
        setLoading(true);
        Promise.all([getAllVendors(),
        getAllTaxes(api),
        getAllBanks(),
        getAllFarms(),
        getAllPurchases(),
        getAllMaterialTypes(),getAllCommissonAgent()]).finally(()=>{
            setLoading(false);
        });
    };

    useEffect(onload, []);


    let purchaseNameColumn = {
        title: 'Vendor Name', field: 'vendorName', editable: 'never', defaultSort: 'asc',
        cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'}
    };

    let actions = [
        rowData => ({
            icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
            tooltip: 'Payment Details',
            onClick: (_, rowData) => onPaymentEdit(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Purchase Details',
            onClick: () =>  onPurchaseEdit(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Purchase Details',
            onClick: (event, rowData) => onDelete(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        })
    ];
    
    let proActions = [
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Purchase Details',
            onClick: () => onProPurchaseEdit(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Purchase Details',
            onClick: (event, rowData) => onDelete(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        })
    ];

    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Purchases
                </Typography>
            </Grid>
            <Grid container
                  spacing={3}
                  justify="flex-end"
                  alignItems="center"
            >
                <Grid item>
                    <Button variant="contained" color="primary" onClick={onNewPurchase}>Add purchase</Button>
                </Grid>
                <Grid item spacing={1}>
                        <Button  variant="outlined" color="primary"
                            disabled={purchases.length < 1}
                            onClick={() => generateExcel( csvHeader,purchases)}
                            endIcon={<GetAppIcon />}>Download Excel</Button>
                    </Grid>
            </Grid>
        </Grid>
        <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="From"
                    format="dd/MM/yyyy"
                    value={filterSelectedStartDate || null}
                    onChange={handleFilterStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="To"
                    format="dd/MM/yyyy"
                    value={filterSelectedEndDate  || null}
                    onChange={handleFilterEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
                {
                    (user.versionID === version.proPlus) && 
                    <FormControl  className={classes.formControl}>
                    <InputLabel id="vendorName-select-outlined-label">
                        Vendor Name
                    </InputLabel>
                    <Select
                    id="vendorName-select"
                    value={selectedVendorId  !== undefined ? selectedVendorId : ''}
                    onChange={handleFilterVendorChange}
                    name="vendorId"
                    >
                    {vendors !== undefined && vendors !== null ?
                        vendors.map((vendor,index)=>
                        <MenuItem key = {vendor.id} value={index}>{vendor.name}</MenuItem>
                    ):""}         
                    </Select>
                </FormControl>
                }
                {true ?
                "" :
                <FormControl  className={classes.formControl}>
                    <InputLabel id="filterSelectedPaymentStatus-select-outlined-label">
                        Payment Status
                    </InputLabel>
                    <Select
                        id="filterSelectedPaymentStatus"
                        value={filterSelectedPaymentStatus !== undefined ? filterSelectedPaymentStatus : ''}
                        onChange={handleFilterPaymentStatus}
                        name="filterSelectedPaymentStatus"
                    >
                         <MenuItem key = {0} value={3}>Paid</MenuItem>
                        <MenuItem key = {1} value={2}>Partially Paid</MenuItem>
                        <MenuItem key = {2} value={1}>Not Paid</MenuItem>
                    </Select>
                </FormControl>
                }
            <Grid>
                <Button onClick={() => setDoSearch(true)} className={classes.success}>Search <SearchIcon/></Button>
                <Button onClick={clearSearchFilter} className={classes.searchButton}>Clear Search <CloseIcon/></Button>
            </Grid>
        </Grid>
        {
            (user.versionID === version.pro) 
            ?
            <PurchaseMaterialTable purchaseMaterials={purchases} actions={proActions}/>
            :
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'No', field: 'id', hidden: false},
                {
                    title: 'Date', field: 'date', type: 'datetime', defaultSort: 'desc',
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                    editable: 'never',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },
                purchaseNameColumn,
                {
                    title: 'Payment Due (in Days)', field: 'paymentDueDate', editable: 'never', hidden: true,
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                },
                
                {
                    title: 'Bill Value', field: 'totalBillValue', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let billValue = 0
                        if (rowData && rowData.purchaseMaterials) {
                            let purchaseMaterials = rowData.purchaseMaterials
                            purchaseMaterials.forEach(purchaseMaterial => {
                                billValue += purchaseMaterial.billValue
                            })
                        }
                        return <span>{(billValue).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'TDS', field: 'tds', editable: 'never',hidden: true,
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let billValue = 0
                        if (rowData && rowData.purchaseMaterials) {
                            let purchaseMaterials = rowData.purchaseMaterials
                            purchaseMaterials.forEach(purchaseMaterial => {
                                billValue += purchaseMaterial.billValue
                            })
                        }
                        return <span>{(parseFloat((billValue * (rowData.tds / 100)).toFixed(2))).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'TCS', field: 'tcs', editable: 'never',hidden: true,
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let gstAmount = 0;
                        if (rowData && rowData.purchaseMaterials) {
                            let purchaseMaterials = rowData.purchaseMaterials
                            purchaseMaterials.forEach(purchaseMaterial => {
                                let gstTaxAmt = parseFloat((purchaseMaterial.billValue * (purchaseMaterial.gst / 100)).toFixed(2))
                                gstAmount += gstTaxAmt
                            })
                        }
                        let billValueTCS = rowData.totalBillValue * (rowData.tcs / 100)
                        let gstValueTCS = gstAmount * (rowData.tcs / 100)
                        let totalTCS = Math.ceil(billValueTCS + gstValueTCS)
                        return <span>{(totalTCS).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'GST', field: 'gst', editable: 'never',hidden: true,
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let gstAmount = 0;
                        if (rowData && rowData.purchaseMaterials) {
                            let purchaseMaterials = rowData.purchaseMaterials
                            purchaseMaterials.forEach(purchaseMaterial => {
                                let gstTaxAmt = parseFloat((purchaseMaterial.billValue * (purchaseMaterial.gst / 100)).toFixed(2))
                                gstAmount += gstTaxAmt
                            })
                        }
                        return <span>{gstAmount.toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Total', field: 'total', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let totalAmount = 0
                        let gstAmount = 0
                        let tdsAmount = 0
                        let tcsAmount = 0
                        let billValue = 0
                        if (rowData && rowData.purchaseMaterials) {
                            let purchaseMaterials = rowData.purchaseMaterials
                            purchaseMaterials.forEach(purchaseMaterial => {
                                let gstTaxAmt = parseFloat((purchaseMaterial.billValue * (purchaseMaterial.gst ? purchaseMaterial.gst : 0) / 100).toFixed(2))
                                let tdsTaxAmt = parseFloat((purchaseMaterial.billValue * (rowData.tds ? rowData.tds : 0) / 100).toFixed(2))
                                let tcsTaxAmt = parseFloat((purchaseMaterial.billValue * (rowData.tcs ? rowData.tcs : 0) / 100).toFixed(2))
                                gstAmount += gstTaxAmt
                                tdsAmount += tdsTaxAmt
                                tcsAmount += tcsTaxAmt
                                billValue += purchaseMaterial.billValue
                            })
                        }
                        let gstTCSVal = gstAmount * (rowData.tcs/100)
                        let totalTCS = Math.ceil(tcsAmount + gstTCSVal)
                        totalAmount = billValue + totalTCS + gstAmount - tdsAmount
                        return <span>{totalAmount.toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Remaining Balance', field: 'remainingBalance', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let totalAmount = 0
                        let gstAmount = 0
                        let tdsAmount = 0
                        let tcsAmount = 0
                        let billValue = 0
                        let totalAmountPaid = 0
                        if (rowData && rowData.purchaseMaterials) {
                            let purchaseMaterials = rowData.purchaseMaterials
                            purchaseMaterials.forEach(purchaseMaterial => {
                                let gstTaxAmt = parseFloat((purchaseMaterial.billValue * (purchaseMaterial.gst ? purchaseMaterial.gst : 0) / 100).toFixed(2))
                                let tdsTaxAmt = parseFloat((purchaseMaterial.billValue * (rowData.tds ? rowData.tds : 0) / 100).toFixed(2))
                                let tcsTaxAmt = parseFloat((purchaseMaterial.billValue * (rowData.tcs ? rowData.tcs : 0) / 100).toFixed(2))
                                gstAmount += gstTaxAmt
                                tdsAmount += tdsTaxAmt
                                tcsAmount += tcsTaxAmt
                                billValue += purchaseMaterial.billValue
                            })
                        }
                        if (rowData && rowData.purchasePayments && rowData.purchasePayments.length) {
                            let purchasePayments = rowData.purchasePayments
                            purchasePayments.forEach(ele => {
                                if (ele.paymentMode !== -1 ) { // Exclude tax payment
                                    totalAmountPaid += ele.paymentAmount
                                }
                            })
                        }
                        let gstTCSVal = gstAmount * (rowData.tcs/100)
                        let totalTCS = Math.ceil(tcsAmount + gstTCSVal)
                        totalAmount = billValue + totalTCS + gstAmount - tdsAmount
                        
                        let reminingAmount = totalAmount - totalAmountPaid;

                        if (rowData.paymentStatus === 2 && reminingAmount < 1) {
                            reminingAmount = 0
                        }

                        return <span>{reminingAmount.toLocaleString(user.locales,amountObj)}</span>

                    }
                },
                {
                    title: 'Payment Status', field: 'paymentStatus', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: (rowData) => {
                        let statusIcon = [
                        <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>,
                        <Tooltip title="Tax Not Paid"><CancelIcon style={{fontSize:"12"}}  className={classes.red} /></Tooltip>
                        ]
                        
                        
                        //Get Taxable Billvalue
                        let purchaseMaterials = rowData.purchaseMaterials
                        let commissionAmount = 0;
                        if(purchaseMaterials !== undefined){
                            purchaseMaterials.forEach((purchaseMaterial) => {
                                if (purchaseMaterial.commissionPerUnit > 0 ) {
                                    if (purchaseMaterial.weightType === 1) {
                                        commissionAmount += (purchaseMaterial.billWeight * (purchaseMaterial.commissionPerUnit));
                                    } else {
                                        commissionAmount += (purchaseMaterial.deliveryWeight *  ( purchaseMaterial.commissionPerUnit));
                                    }
                                }
                            })
                        }
                        // let tdsValue = rowData.totalBillValue * (rowData.tds / 100)
                        let remaningBalance = (rowData.totalBillValue - ((rowData.totalBillValue * (rowData.tcs / 100))) - (rowData.totalBillValue * (rowData.tds/100)) - rowData.totalAmountPaid).toFixed(2)

                        let remaningBalanceWithoutTaxes = (((rowData.totalBillValue -commissionAmount)  * (rowData.tcs / 100)) + ((rowData.totalBillValue -commissionAmount)  * (rowData.tds/100))) - rowData.totalTaxAmountPaid


                        if (rowData.paymentStatus === 1 && remaningBalance >= 1) {
                            statusIcon.shift()
                            statusIcon.unshift(<Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>)
                        } else if (rowData.paymentStatus === 2 || (rowData.paymentStatus === 1 && remaningBalance < 1)) {
                            statusIcon.shift()
                            statusIcon.unshift(<Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>)
                        }

                        if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes >= 1) { // Partially paid
                            statusIcon.pop()
                            statusIcon.push(<Tooltip title="Tax Partially Paid"><QueryBuilderIcon style={{fontSize:"12"}}  /></Tooltip>)
                        } else if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes < 1) { // Fully paid
                            statusIcon.pop()
                            statusIcon.push(<Tooltip title="Tax Paid"><CheckCircleIcon style={{fontSize:"12"}}  className={classes.green} /></Tooltip>)
                        }

                        if (rowData.tcs === 0 && rowData.tds === 0) {
                            statusIcon.pop()
                        }

                        return <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }} >{statusIcon}</div>
                    }
                }
            ]}
            data={purchases}
            title='Purchase Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true,
            }}
            onRowClick={openPurchasesDetailsDialog }
            actions={actions}
        />
        }
        <Purchase deleteErrorMessage={removeErrorMessage} purchaseerrorMessage={errorMessage} showDialog={showPurchaseDialog} setDialog={setShowPurchaseDialog} handleClose={handlePurchaseClose}  onSave={onPurchaseSave} onEdit={onPurchaseUpdate}
               editMode={editMode} data={currentPurchase} vendors={vendors} materialTypes={materialTypes} resetBillTotal={resetBillTotal} setResetBillTotal={setResetBillTotal}
               farms={farms}  sheds={sheds} showError={showError} showMessage={showMessage} commissionAgents={commissionAgents}/>

        <PurchasePaymentDetails showPaymentDetailsDialog={showPurchasePaymentDialog} handlePaymentDetailsClose={handlePurchasePaymentClose} 
               onPaymentEdit={onPurchasePaymentUpdate} data={currentPurchasePayment} banks={banks} onPurchasePaymentSave={onPurchasePaymentSave}
               onPurchasePaymentDelete = {onPurchasePaymentDelete}  loading={loading}/>

        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onPurchaseDeleteConfirm}
                       title='Delete Purchase ?'
                       subText='This action will delete the Purchase Details. Deleting the Purchase will affect any daily feed production rates that were run already. You will have to re-edit and save those daily feed production entries in order to compute the new cost. Please confirm.'/>
        <ConfirmDialog showDialog={showEditConfirm}
                       handleClose={handlePurchaseClose}
                       onConfirm={onPurchaseEditConform}
                       title='Edit Purchase ?'
                       subText='This action will Edit the Purchase Details. Editing the Purchase will affect any daily feed production rates that were run already. You will have to re-edit and save those daily feed production entries in order to compute the new cost. Please confirm.'/>
        <PurchaseDetails handlePurchaseDetailsDialogClose={handlePurchaseDetailsDialogClose}
                         showPurchaseDetailsDialog={showPurchaseDetailsDialog}
                         data={currentPurchase}
                         onPaymentEdit={onPaymentEdit}
                        onPurchaseEdit={onPurchaseEdit}
                        onDelete={onDelete}
                         />
    </div>
};

export default PurchasesView;