const version = {
    lite: 100,
    pro: 200,
    proPlus: 300,
}

const standardPageSizes = [5, 10, 20];

const numberOfEggsInTray = 30

const MaterialTypeId = {
    RAW_MATERIALS: 1,
    MEDICINE: 2,
    FEED_SUPPLEMENTS: 3,
    VACCINATION: 4,
    FEED: 5,
    EGGS: 6,
    BIRDS: 7,
    MANURE: 8,
    OTHER: 9,
}

export  {
    version,
    standardPageSizes,
    numberOfEggsInTray,
    MaterialTypeId
}