
import {version} from "../common/enums";

const common = ["/","/sign-up","/sign-in","/forbidden","/not-found","/success-registration","/activate/:key"];

const litePaths = {
    SUPER  : ["/farm","/farm/:farmID/daily-entry"],
    DRIVER : [],
    DOCTOR : ["/farm","/doctorprofilepage","/farm/:farmID/dashboard","/farm/:farmID/reports/single-batch-stock",
              "/farm/:farmID/reports/single-batch","/farm/:farmID/reports/single-batch-weekly","/farm/:farmID/reports/all-batch-daily","/farm/:farmID/labour-page"],
    STAFF  : ["/farm","/breed","/breed/:breedID/vaccination-schedule","/breed/:breedID/std-prod","/material_master","/farm/:farmID/dashboard","/farm/:farmID/daily-entry",
              "/farm/:farmID/shed","/farm/:farmID/batch","/farm/:farmID/reports/single-batch-stock","/farm/:farmID/reports/single-batch",
              "/farm/:farmID/reports/single-batch-weekly","/farm/:farmID/reports/all-batch-daily","/farm/:farmID/labour-page","/farm/:farmID/labour","/farm/:farmID/labour-Attendance" ],
    ADMIN  : ["/farm","/breed","/breed/:breedID/vaccination-schedule","/breed/:breedID/std-prod","/material_master","/subuser","/subscriptions","/profilepage","/farm/:farmID/dashboard","/farm/:farmID/daily-entry",
              "/farm/:farmID/shed","/farm/:farmID/batch","/farm/:farmID/reports/single-batch-stock","/farm/:farmID/reports/single-batch",
              "/farm/:farmID/reports/single-batch-weekly","/farm/:farmID/reports/all-batch-daily","/farm/:farmID/labour-page","/farm/:farmID/reports/daily-profit-loss","/farm/:farmID/labour","/farm/:farmID/labour-Attendance","/farm/:farmID/labour-payment","/farm/:farmID/labour-report"],
    FEEDSUPER  : ["/daily-entry-feed"],
            };

const proPaths = {
    SUPER  : ["/farm","/farm/:farmID/daily-entry"],
    DRIVER : [],
    DOCTOR : ["/farm","/doctorprofilepage","/farm/:farmID/dashboard","/farm/:farmID/reports/single-batch-stock",
                "/farm/:farmID/reports/single-batch","/farm/:farmID/reports/single-batch-weekly","/farm/:farmID/reports/all-batch-daily","/farm/:farmID/reports/all-batch-stock"],
    STAFF  : ["/farm","/purchase","/sale","/breed","/breed/:breedID/vaccination-schedule","/breed/:breedID/std-prod","/material_master","/bank","/farm/:farmID/dashboard","/farm/:farmID/daily-entry",
             "/farm/:farmID/shed","/farm/:farmID/batch","/farm/:farmID/reports/single-batch-stock","/farm/:farmID/reports/single-batch",
            "/farm/:farmID/reports/all-batch-daily","/farm/:farmID/medicine-stock","/farm/:farmID/labour-page","/daily-egg-rates","/farm/:farmID/labour","/farm/:farmID/labour-Attendancer","/farm/:farmID/reports/all-batch-stock", "/other-expense","/other-income","/expense-type","/income-type" ],
    ADMIN  : ["/farm","/purchase","/sale","/breed","/breed/:breedID/vaccination-schedule","/breed/:breedID/std-prod","/material_master","/bank","/subuser","/subscriptions","/profilepage","/farm/:farmID/dashboard","/farm/:farmID/daily-entry",
                "/farm/:farmID/shed","/farm/:farmID/batch","/farm/:farmID/reports/single-batch-stock","/farm/:farmID/reports/single-batch","/farm/:farmID/reports/daily-profit-loss",
                "/farm/:farmID/reports/single-batch-weekly","/farm/:farmID/reports/all-batch-daily","/farm/:farmID/labour-page","/daily-egg-rates","/farm/:farmID/labour" ,"/farm/:farmID/labour-Attendance","/farm/:farmID/labour-payment" ,"/farm/:farmID/labour-report","/farm/:farmID/reports/all-batch-stock",
                "/other-expense","/other-income","/expense-type","/farm/:farmID/medicine-stock","/income-type","/trail-balance-report","/config"],
    FEEDSUPER  : ["/daily-entry-feed"],
            };

const proPlusPaths = {
    SUPER  : ["/dashboard","/farm/:farmID/daily-entry"],
    FEEDSUPER  : ["/daily-entry-feed"],
    DRIVER : ["/line-sale-driver"],
    DOCTOR : ["/dashboard","/doctorprofilepage","/farm/:farmID/dashboard","/farm/:farmID/reports/single-batch-stock","/farm/:farmID/reports/single-batch",
              "/farm/:farmID/reports/single-batch-weekly","/farm/:farmID/reports/all-batch-daily","/farm/:farmID/reports/all-batch-stock","/farm/:farmID/reports/all-batch-weekly",
              "/farm/:farmID/reports/all-batch-stock-statement","/farm/:farmID/reports/eggs-production","/farm/:farmID/labour-page"],
    STAFF  : ["/dashboard","/farm","/purchase","/other-expense","/vendor-bulk-payments","/vendor-report","/vendor-tds-report",
              "/vendor-bill-balance-report","/vendor-yearly-tax-report","/sale","/other-income","/customer-bulk-payments",
              "/customer-report","/customer-tds-report","/customer-bill-balance-report","/customer-yearly-tax-report",
              "/daily-egg-rates","/feed-formulation","/daily-entry-feed","/reports/rawmaterials","/stock-adjustments",
              "/feed-formulation/:feedFormulationID/feed-formulation-material","/feed-formulation/:feedFormulationID/feed-formulation-medicine",
              "/reports/stockStatement","/reports/stockAvailability","/material-nutrition-profile","/customer-vendor-details",
              "/breed","/breed/:breedID/vaccination-schedule","/breed/:breedID/std-prod","/material_master","/invoice_settings","/expense-type","/income-type","/bank","/farm/:farmID/dashboard","/farm/:farmID/daily-entry", "/farm/:farmID/shed", "/farm/:farmID/batch",
              "/farm/:farmID/medicine-stock","/farm/:farmID/available-stock","/farm/:farmID/batch-shed-availability",
              "/farm/:farmID/reports/single-batch-stock","/farm/:farmID/reports/single-batch","/farm/:farmID/reports/single-batch-weekly",
              "/farm/:farmID/reports/all-batch-daily","/farm/:farmID/reports/all-batch-stock","/farm/:farmID/reports/all-batch-weekly",
              "/farm/:farmID/reports/all-batch-stock-statement","/farm/:farmID/reports/eggs-production","/farm/:farmID/labour-page","/farm/:farmID/farmStorage","/farm/:farmID/farmStorageReport","/farm/:farmID/labour","/farm/:farmID/labour-Attendance","/feed-formulation-storage","/feed-storage-stock",
            "/commission-report" ],

    ADMIN  : ["/dashboard","/farm","/accounts-dashboard","/purchase","/other-expense","/vendor-bulk-payments","/vendor-report",
              "/vendor-tds-report","/vendor-bill-balance-report","/sale","/other-income","/customer-bulk-payments","/customer-report",
              "/customer-tds-report","/customer-bill-balance-report","/daily-egg-rates","/trail-balance-report","/day-book-report",
              "/reconciliation-report","/bank-statement-report","/cash-statement-report","/feed-formulation",
              "/feed-formulation/:feedFormulationID/feed-formulation-material","/feed-formulation/:feedFormulationID/feed-formulation-medicine",
              "/daily-entry-feed","/reports/rawmaterials","/stock-adjustments",
              "/reports/stockStatement","/reports/stockAvailability","/material-nutrition-profile","/tax-payments","/customer-tds-report",
              "/vendor-tds-report","/customer-yearly-tax-report","/vendor-yearly-tax-report","/line-sale","/line-sale-truck","/line-expense-type",
              "/line-expenses","/line-customer-vendor","/line-purchase","/line-sale-bulk-payments","/line-purchase-bulk-payments","/line-sale-report",
              "/storage-report","/line-report","/line-customer-report","/line-vendor-report","/line-tax-payments","/line-customer-tds-report","/line-vendor-tds-report",
              "/line-customer-yearly-tax-report","/line-vendor-yearly-tax-report","/breed","/breed/:breedID/vaccination-schedule",
              "/breed/:breedID/std-prod","/material_master","/customer-vendor-details","/invoice_settings",
              "/expense-type","/income-type","/bank","/line_name","/subuser","/subscriptions","/taxes","/profilepage",
              "/config","/cpanel","/farm/:farmID/dashboard","/farm/:farmID/daily-entry", "/farm/:farmID/shed","/farm/:farmID/batch",
              "/farm/:farmID/medicine-stock","/farm/:farmID/available-stock","/farm/:farmID/batch-shed-availability",
              "/farm/:farmID/reports/single-batch-stock","/farm/:farmID/reports/single-batch","/farm/:farmID/reports/single-batch-weekly",
              "/farm/:farmID/reports/all-batch-daily","/farm/:farmID/reports/all-batch-stock","/farm/:farmID/reports/all-batch-weekly",
              "/farm/:farmID/reports/all-batch-stock-statement","/farm/:farmID/reports/eggs-production","/farm/:farmID/labour-page","/farm/:farmID/farmStorage","/farm/:farmID/farmStorageReport",
              "/bank-transaction","/farm/:farmID/reports/daily-profit-loss","/farm/:farmID/labour","/farm/:farmID/labour-Attendance","/farm/:farmID/labour-payment","/farm/:farmID/labour-report","/feed-formulation-storage",
              "/feed-storage-stock", "/commission-report"],
            };

const isUserHasPathAccess = (path,user)=>{
    if(common.includes(path)){
        return true;
    } 
    //Allow only kumar account
    if(path === "/cpanel" && user.accountID !== 2){
        return false;
    }
    
    var comVersion = {};
    switch(user.versionID){
        case version.lite:
            comVersion = litePaths;
            break;
        case version.pro:
            comVersion = proPaths;
            break;
        case version.proPlus:
            comVersion = proPlusPaths;
            break;
        default:
            break;
    }
    if((comVersion[user.role] !== undefined && comVersion[user.role].includes(path))){
        return true;
    }

    return false;
};

export {
    litePaths,
    proPaths,
    proPlusPaths,
    isUserHasPathAccess
}