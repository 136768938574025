import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useFromValidator from "../../../../common/formValidator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {useGlobalState} from '../../../../state';
import { version } from '../../../../common/enums';
/**
 *
 * This component renders non weight type related data - units, litres, trays
 */

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    minWidth: 160,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  datePicker: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    minWidth: 160,
  },
  dialogWidth: {
    minWidth: "1042px",
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "40%",
    zIndex: "9999",
  },
}));

function NonWeightTypeMaterial({
  showNonWeightType,
  setDisabled,
  materialData,
  setMaterialData,
  inventoryTypeFarm,
  farms,
  sheds,
  onGetShed,
  reset,
  editMode,
  data,
  schema,
  materialNames,
  materialIndex,
  isCommissionPurchase
}) {
  const classes = useStyles();
  const [{user}] = useGlobalState();
  // eslint-disable-next-line
  const [formState, hasError, handleChange, updateData, setFormState] =
    useFromValidator(schema);
  const [displayShed, setDisplayShed] = useState(false);
  const [storageDisabled, setStorageDisabled] = useState(false);
  const [shedDisabled, setShedDisabled] = useState(false);

  const resetBeforeEdit = () => {
    if (!editMode) {
      updateData({}, false);
      setDisplayShed(false);
      setStorageDisabled(false);
      setShedDisabled(false);
    } else {
      enableOrDisbleFieldsOnEdit(materialData);
      updateData(materialData, editMode);
    }
  };

  /**
   *
   * The below function executes during edit mode
   * if part executes on the first time when the material dialog is opened
   * else part covers the corner case where in case if the same material is
   * switched back after selecting a different material.
   */

  const enableOrDisbleFieldsOnEdit = (data) => {
    //In edit mode either case display storage checkbox to revert back to shed/storage option
    if (inventoryTypeFarm === true && Object.keys(data).length > 0) {
      setDisplayShed(true);
      setStorageDisabled(false);
      setShedDisabled((user.versionID === version.proPlus && data.storage === true) ? true : (user.versionID === version.pro && data.storage === 1) ? true : false);
      data.shedIndex = data.shedIndex === -1 ? "" : data.shedIndex;
    } else {
      setDisplayShed(false);
      setShedDisabled(false);
      setStorageDisabled(false);
    }
  };

  const handleFarm = (event) => {
    onGetShed(farms[event.target.value].id);
    setDisplayShed(true);
    let obj = {
      shedIndex: {
        presence: { allowEmpty: false, message: "is required" },
        type: "number",
      },
    };
    Object.assign(schema, obj);
  };

  const handleStorage = (event) => {
    setShedDisabled(event.target.checked);

    if (event.target.checked === true) {
      delete schema.shedIndex;
    } else {
      let obj = {
        shedIndex: {
          presence: { allowEmpty: false, message: "is required" },
          type: "number",
        },
      };
      Object.assign(schema, obj);
    }
  };

  useEffect(() => {
    if (formState.isValid === true) {
      setDisabled(false);
      let storageAndShed = {
        storage: Number(formState.values.storage),
        shedIndex:
          formState.values.shedIndex !== undefined &&
          formState.values.shedIndex !== null
            ? formState.values.shedIndex
            : -1,
        shedId:
          sheds[formState.values.shedIndex] === undefined ||
          sheds[formState.values.shedIndex] === null ||
          shedDisabled === true
            ? -1
            : sheds[formState.values.shedIndex].id,
        farmId:
          farms[formState.values.farmIndex] === undefined ||
          farms[formState.values.farmIndex] === null
            ? 0
            : farms[formState.values.farmIndex].id,
      };
      let obj = {
        ...formState.values,
        ...storageAndShed,
        billWeight: formState.values.quantity,
        deliveryWeight: formState.values.quantity,
        unitID:
          materialNames !== undefined &&
          materialNames !== null &&
          materialNames[materialIndex] !== undefined &&
          materialNames[materialIndex] !== null &&
          materialNames[materialIndex].unitID,
      };
      setMaterialData({ ...materialData, ...obj });
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [formState]);

  const handleShedChange = (event) => {
    setStorageDisabled(true);
  };

  useEffect(() => {
    resetBeforeEdit();
    // eslint-disable-next-line
  }, [reset]);

  return (
    <span>
      {showNonWeightType === true && (
        <>
          {inventoryTypeFarm === true && (
            <>
              <FormControl className={classes.formControl}>
                <InputLabel id="farm-select-outlined-label">Farm</InputLabel>
                <Select
                  id="farm"
                  value={
                    formState.values.farmIndex !== undefined
                      ? formState.values.farmIndex
                      : ""
                  }
                  onChange={(event) => {
                    handleChange(event, handleFarm);
                  }}
                  name="farmIndex"
                >
                  {farms.map((farm, index) => (
                    <MenuItem key={farm.id} value={index}>
                      {farm.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {displayShed === true && (
                <>
                {
                  (user.versionID !== version.lite) && 
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            handleChange(event, handleStorage);
                          }}
                          name="storage"
                          checked={
                            formState.values.storage !== undefined
                              ? formState.values.storage
                              : ""
                          }
                          disabled={storageDisabled}
                        />
                      }
                      label="Storage"
                      style={{ marginTop: "14px", marginLeft: "-15px" }}
                    />
                  </FormControl>
                }
                  <FormControl className={classes.formControl}>
                    <InputLabel id="shed-select-outlined-label">
                      Shed
                    </InputLabel>
                    <Select
                      id="shed"
                      value={
                        formState.values.shedIndex !== undefined
                          ? formState.values.shedIndex
                          : ""
                      }
                      onChange={(event) => {
                        handleChange(event, handleShedChange);
                      }}
                      name="shedIndex"
                      disabled={shedDisabled}
                    >
                      {sheds.map((shed, index) => (
                        <MenuItem key={shed.id} value={index}>
                          {shed.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </>
          )}
          <TextField
            className={classes.textField}
            error={hasError("Quantity")}
            fullWidth
            helperText={
              hasError("Quantity") ? formState.errors.quantity[0] : null
            }
            label="Quantity"
            name="quantity"
            onChange={handleChange}
            type="number"
            value={
              formState.values.quantity !== undefined
                ? formState.values.quantity
                : ""
            }
            variant="outlined"
            style={{ backgroundColor: "#ffffff" }}
          />
          <TextField
            className={classes.textField}
            error={hasError("costPerUnit")}
            fullWidth
            helperText={
              hasError("costPerUnit") ? formState.errors.costPerUnit[0] : null
            }
            label="Cost Per Unit"
            name="costPerUnit"
            onChange={handleChange}
            type="number"
            value={
              formState.values.costPerUnit !== undefined
                ? formState.values.costPerUnit
                : ""
            }
            variant="outlined"
            style={{ backgroundColor: "#ffffff" }}
          />
          {
           isCommissionPurchase ? 
              <TextField
              className={classes.textField}
              error={hasError("commissionPerUnit")}
              fullWidth
              helperText={
                hasError("commissionPerUnit") ? formState.errors.CommissionPerUnit[0] : null
              }
              label="Commission Per Unit"
              name="commissionPerUnit"
              defaultValue={0}
              onChange={handleChange}
              type="number"
              value={
                formState.values.CommissionPerUnit !== undefined
                  ? formState.values.CommissionPerUnit
                  :  0
              }
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            /> :""
            }
        </>
      )}
    </span>
  );
}

export default NonWeightTypeMaterial;
