import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
    '@media print': {
        overflowX: 'visible',
    },
  },
});


function TableWrapper({ children, className, ...rest }) {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className || ''}`.trim()} {...rest}>
      {children}
    </div>
  )
}

export default TableWrapper