import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider,DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useGlobalState} from '../../../state';
import Switch from '@material-ui/core/Switch';
var schema = {
    paymentDate: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    paymentAmount: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0,
        }
    },
    paymentMode: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'number',
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        paddingBottom: theme.spacing(1.25)
    },
    textField: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(0)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth:160
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const PurchasePayement = ({data, paymentData, banks, onSave, handlePaymentClose, showPaymentDialog, editMode}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false);
    const [showBanks, setShowBanks] = useState(false);
    const [maxPayAmt, setMaxPayAmt] = useState(undefined);
    const [{user}] = useGlobalState();
    const [commissionAgentSwitch,setCommissionAgentSwitch] = useState(false);
    const isPro    = (user.versionID === 200);
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (data!== undefined && data !== null) {
            let tds = 0;
            let tcs = 0;
            let gst = 0;
            let tax = 0;
            let paidAmount = 0;

            function gstTaxAmt(itemData){
               return parseFloat((itemData.billValue * (itemData.gst / 100)).toFixed(2));
            }
            let maxAmount;
            if(!isPro){
                data.purchaseMaterials.forEach(purchaseMaterial => {
                    gst += gstTaxAmt(purchaseMaterial)
                })
            }
                if (data.purchasePayments) {
                    data.purchasePayments.forEach(payment => {
                        paidAmount += payment.paymentAmount
                    })
                }

                if(!isPro){
                    if (data.tds && data.tds > 0){
                        tds = (data.tds * data.totalBillValue)/100
                    } 
                    if (data.tcs && data.tcs > 0) {
                        tcs = ((data.tcs * data.totalBillValue)/100) + ((data.tcs * gst)/100)
                    }
                    
                    tax = Math.ceil(tcs + gst) - tds
                    maxAmount = Math.round(data.totalBillValue - paidAmount + tax);
                }
                maxAmount = Math.round(data.totalBillValue - paidAmount + tax);
    
                if (paymentData) {
                    maxAmount += paymentData.paymentAmount
                }
            setMaxPayAmt(maxAmount)   
        }
        console.debug(data)
        if(showPaymentDialog){
            console.log("currentPayment", data, 86)
            updateData(paymentData, editMode);
            setPopupLoading(false);
        }
        if(editMode){
            if (paymentData?.isCommissionPayment === 1 ) {
                setCommissionAgentSwitch(true)
            }else{
                setCommissionAgentSwitch(false)
            }
            if( paymentData !== undefined && paymentData.paymentMode !== undefined && paymentData.paymentMode === 2){
                setShowBanks(true)
            }else{
                setShowBanks(false)
            }
        }
    };
    useEffect(resetBeforeEdit, [showPaymentDialog]);

    const onSubmit = () => {
        setPopupLoading(true)
        let tempData = {
            ...formState.values,
            isCommissionPayment:commissionAgentSwitch ? 1 : 0
        };
        if(tempData.paymentMode === 2){
            tempData = {
                ...tempData,
                paymentBankId:banks[formState.values.bankIndex].id,
                paymentBankName:{
                    String:banks[formState.values.bankIndex].name,
                }
            }
        }
        console.debug(tempData)
        onSave(tempData, setPopupLoading);
    };

    const handleChangePaymentMode = (event) => {
        if(event.target.name === "paymentMode" && event.target.value === 2){
            console.debug("in handle ",event)
            schema['bankIndex'] = {
                    presence: {allowEmpty: false, message: 'is required'},
                    type: 'number',
            };

            if(formState.values.bankIndex && formState.values.bankIndex < 0){
                updateData({
                    ...formState.values,
                    bankIndex : '',
                    paymentMode : 2
                },true);
            }
            setShowBanks(true)
        }else{
            if(schema.bankIndex){
                delete schema.bankIndex;
            }
            setShowBanks(false)
        }
    }
  

    return <Dialog disableBackdropClick open={showPaymentDialog} onClose={handlePaymentClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"> {editMode ? "Update " :"Add "} Purchase Payment
        <IconButton aria-label="close" className={classes.closeButton} onClick={handlePaymentClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <span hidden={maxPayAmt <= 0} style={{color:"red", textAlign:"center", padding:"3px", fontSize:"15px"}}>{formState.values.paymentAmount > maxPayAmt ? `payment amount exceeding the remaining balance = ${maxPayAmt.toLocaleString(user.locales,amountObj)} ` : ""}</span>
          <DialogContent  >{/*The pop-up code is written twice in if else, so that when loading, the pop-up does nota shrink. */}
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <br/><br/>
                <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker disabled
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => {
                    handleChange({target: {name: 'paymentDate', value: data}})
                }}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>
            <TextField disabled
                className={classes.textField}
                error={hasError('paymentAmount')}
                helperText={
                    hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                }
                label="Amount"
                name="paymentAmount"
                onChange={handleChange}
                type="number"
                value={formState.values.paymentAmount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl  disabled className={classes.formControl}>
                <InputLabel disabled id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select disabled
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem key = {0} value={1}>Cash</MenuItem>
                    <MenuItem key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl className={classes.formControl}>
                <InputLabel id="banks-select-label">Banks</InputLabel>
                <Select
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
            <TextField disabled
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('paymentNote')}
                fullWidth
                helperText={
                    hasError('paymentNote') ? formState.errors.paymentNote[0] : null
                }
                label="Note"
                name="paymentNote"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions> 
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
            :
            <div>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => {
                    handleChange({target: {name: 'paymentDate', value: data}})
                }}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>
            <TextField
                className={classes.textField}
                error={hasError('paymentAmount')}
                helperText={
                    hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                }
                label="Amount"
                name="paymentAmount"
                onChange={handleChange}
                type="number"
                value={formState.values.paymentAmount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl  className={classes.formControl}>
                <InputLabel id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem key = {0} value={1}>Cash</MenuItem>
                    <MenuItem key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl className={classes.formControl}>
                <InputLabel id="banks-select-label">Banks</InputLabel>
                <Select
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
              <div style={{display:"flex",marginBottom:"10px"}}>   
                <div style={{marginTop:"10px"}}>
                        Commission Payment
                </div>
                <div>
                    <Switch
                        checked={commissionAgentSwitch}
                        onChange={(event)=>{
                            setCommissionAgentSwitch(!commissionAgentSwitch)

                        }}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
             </div>
            <TextField
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('paymentNote')}
                fullWidth
                helperText={
                    hasError('paymentNote') ? formState.errors.paymentNote[0] : null
                }
                label="Note"
                name="paymentNote"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
          }
        </DialogContent>
    </Dialog>
};

export default PurchasePayement;