import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MaterialTable } from "../../components";
import api from "../../common/api";
import Mobile from "./components/mobile";
import BatchShedAvailability from "./components/batchShedAvailability";


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1),
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    activeFlag: {
        background:"green",
        color:"white",
        borderRadius:"20px",
        padding:"0px 20px",
        fontWeight:"700"
    },
    typeLabel:{
        borderRadius:"6px",
        padding:"3px",
        fontWeight:"700",
        marginTop:"5px"
    },
    typeLabelAvtive:{
        borderRadius:"6px",
        padding:"3px",
        fontWeight:"700",
        marginTop:"5px",
        display:"flex",
        justifyContent:"space-around",
        alignItems:"center"
    }
}));

let currentData = undefined;
let editMode = false;
const BatchShedAvailabilities = ({ showError, showMessage, match }) => {
    const farmID = match.params['farmID'];
    const [loading, setLoading] = useState(false);
    const [showBatchShedAvailabilityDialog, setShowBatchShedAvailabilityDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const [batchShedAvailabilities, setBatchShedAvailabilities] = useState([]);

    const handleBatchShedAvailabilityClose = () => {
        currentData = undefined;
        setShowBatchShedAvailabilityDialog(false);
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onBatchShedAvailabilityEdit = (batchShedAvailability) => {
        currentData = {
            ...batchShedAvailability
        };
        editMode = true;
        setShowBatchShedAvailabilityDialog(true);
    };

    const getAll = () => {
        setLoading(true);
        showError('');
        showMessage('');
        const response = api.get(`farm/${farmID}/batch-shed-availability`);

        response.then(res => {
            setBatchShedAvailabilities([...res]);
        }).catch(err => {
            if (err.message) {
                console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const onBatchShedAvailabilityUpdate = (batchShedAvailability) => {
        setLoading(true);
        // showError('');
        const response = api.post(`farm/${farmID}/batch-shed-availability/${batchShedAvailability.id}`, {
            ...batchShedAvailability
        });
        response.then((res) => {
            let tempBatchShedAvailabilities = batchShedAvailabilities;

            if (res.previousId.Valid && res.previousIdEndDate.Valid) {
                let previousIdIndex = tempBatchShedAvailabilities.findIndex((srcData) => {
                    return srcData.id === res.previousId.Int64;
                });

                let previousElement = {
                    ...tempBatchShedAvailabilities[previousIdIndex],
                    endDate: res.previousIdEndDate,
                    oldEndDate: res.previousIdEndDate
                }

                tempBatchShedAvailabilities = ([...tempBatchShedAvailabilities.slice(0, previousIdIndex),
                { ...previousElement },
                ...tempBatchShedAvailabilities.slice(previousIdIndex + 1)]);
            }

            if (res.nextId.Valid && res.nextIdStartDate.Valid) {
                let nextIdIndex = tempBatchShedAvailabilities.findIndex((srcData) => {
                    return srcData.id === res.nextId.Int64;
                });

                let nextElement = {
                    ...tempBatchShedAvailabilities[nextIdIndex],
                    startDate: res.nextIdStartDate,
                    oldStartDate: res.nextIdStartDate
                }

                tempBatchShedAvailabilities = ([...tempBatchShedAvailabilities.slice(0, nextIdIndex),
                { ...nextElement },
                ...tempBatchShedAvailabilities.slice(nextIdIndex + 1)]);
            }

            let index = tempBatchShedAvailabilities.findIndex((srcData) => {
                return srcData.id === res.id;
            });

            tempBatchShedAvailabilities = ([...tempBatchShedAvailabilities.slice(0, index),
            { ...res },
            ...tempBatchShedAvailabilities.slice(index + 1)]);

            setBatchShedAvailabilities([...tempBatchShedAvailabilities])
            setShowBatchShedAvailabilityDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Batch Shed Availability not updated');
                else if (err.message === 'Duplicate Key')
                setErrorMessage('Duplicate Entry');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };


    useEffect(getAll, []);

    let batchShedAvailabilityColumnDefaults = { title: 'Batch Name', field: 'batchName',
        headerStyle: { 
            textAlign: 'center'  
        },
        cellStyle: { 
            textAlign: 'center' ,
            fontWeight:"600"
        },
     };
    let batchShedAvailabilityColumn = {
        ...batchShedAvailabilityColumnDefaults,
        render: rowData => <Mobile data={rowData} onEdit={()=>{onBatchShedAvailabilityEdit(rowData)}}
        />
    };
    if (isDesktop) {
        batchShedAvailabilityColumn = batchShedAvailabilityColumnDefaults;
    }
    return (
        <div className={classes.root}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                <Grid>
                    <Typography display="block" variant="h3" gutterBottom>
                        Batch Shed Availability
                    </Typography>
                </Grid>
            </Grid>
            <MaterialTable
                spacing={5}
                isLoading={loading}
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    batchShedAvailabilityColumn,
                    {
                        title: 'Chick', field: 'ChickDateRange', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        headerStyle: { 
                            textAlign: 'center'  ,
                            marginLeft:"10%"
                        },
                        cellStyle: { 
                            textAlign: 'center' 
                        },
                        render: rowData => {
                            return (
                                <div style={{ textAlign: "center", width: "100%",fontWeight: "600" }}>
                                    <div>
                                        {rowData.chickName}
                                    </div>
                                    <div style={{padding:"0px 35px"}}>
                                        <div style={{background:rowData.chickName !== "" ? "#E8F7F4" :""}} 
                                            className={rowData.chickActive ? classes.typeLabelAvtive :classes.typeLabel}>
                                                             <div> {rowData.ChickDateRange}</div>
                                                             {
                                                                 rowData.chickActive ? 
                                                                    <div className={classes.activeFlag}>Active</div>  : ""
                                                             }
                                            </div>
                                    </div>
                                </div>
                            );
                        }
                    },
                    {
                        title: 'Grower', field: 'GrowerDateRange', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        headerStyle: { 
                            textAlign: 'center'  
                        },
                        cellStyle: { 
                            textAlign: 'center' 
                        },
                        render: rowData => {
                            return <div style={{ textAlign: "center", width: "100%",fontWeight: "600" }}>
                                        <div>
                                            {rowData.growerName}
                                        </div>
                                        <div style={{padding:"0px 35px"}}>
                                            <div style={{background:rowData.growerName !== "" ? "#FFF4C9" :""}} 
                                            className={rowData.growerActive ? classes.typeLabelAvtive :classes.typeLabel}>
                                                             <div> {rowData.GrowerDateRange}</div>
                                                             {
                                                                 rowData.growerActive ? 
                                                                    <div className={classes.activeFlag}>Active</div>  : ""
                                                             }
                                            </div>
                                        </div>
                                </div>
                        }
                    },
                    {
                        title: 'Layer', field: 'LayerDateRange', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        headerStyle: { 
                            textAlign: 'center'  
                        },
                        cellStyle: { 
                            textAlign: 'center' 
                        },
                        render: rowData => {
                            return  <div style={{ textAlign: "center", width: "100%",fontWeight: "600" }}>
                                                <div>
                                                    <div>{rowData.layerName}</div>
                                                </div>
                                                <div style={{padding:"0px 35px"}}>
                                                    <div  style={{background:rowData.layerName !== "" ? "#F0E8FE" :""}}
                                                    className={rowData.layerActive ? classes.typeLabelAvtive :classes.typeLabel}>
                                                             <div> {rowData.LayerDateRange}</div>
                                                             {
                                                                 rowData.layerActive ? 
                                                                    <div className={classes.activeFlag}>Active</div>  : ""
                                                             }
                                                    </div>
                                                
                                                </div>
                                        </div>
                        }
                    }
                ]}
                data={batchShedAvailabilities}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    showTitle: true,
                    
                    toolbar: true
                }}
                title='Batch Shed Availability Table'
            />
            <BatchShedAvailability deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showBatchShedAvailabilityDialog} handleClose={handleBatchShedAvailabilityClose}
                onEdit={onBatchShedAvailabilityUpdate} editMode={editMode} data={currentData} />
        </div>
    );
};

export default BatchShedAvailabilities;