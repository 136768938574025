import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";
import api from '../../../common/api';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";



import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Chip from '@material-ui/core/Chip';
import { version, numberOfEggsInTray, MaterialTypeId } from "../../../common/enums";
import {useGlobalState} from '../../../state';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import { MaterialFunc } from '../../../common/material';
var schema = {
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'integer',
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    costPerUnit: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0
        },
    },
    /*inventoryType: {
        presence: { allowEmpty: false, message: 'is required' }
        },*/
    freeEggs: {
        presence: false,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 0,
            message: "must be a non-negative integer or zero"
        }
    },
    materialIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    discountPerUnit: {
        presence: false
    },
    billValue: {
        presence: false
    },
    farmIndex: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
    },
    isStorageUnit: {
        presence: false,
    },

};
const presenceTrue = {
    presence: { allowEmpty: false, message: 'is required' },
}

const presenceFalse = {
    presence: false
}
const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    brokenEggsSwitch: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(4),
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160,
    },
    dialogWidth: {
        minWidth: "1042px"
    },
    typo: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    billValue: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        '& span': {
            fontWeight: 'bold',
        },
    },
}));
const Material = ({ farms, sheds, onGetShed, onEdit, data,showError, onSave, handleClose, showDialog, editMode, materialIndex, eggDetail,
    onGetUnInvoicedQuantity, unInvoicedQuantity, onGetMaterialNames, materialTypes, materialNames, lineSaleCheck,reload=false,errorMessage,deleteErrorMessage,gstTaxProfiles,onGetFarmStorage,farmStorageStock,handlechangeStorageStock,isCommissionSale }) => {
        const [{user}] = useGlobalState();
    if(user.versionID === version.pro){
        schema['date'] = {presence: { allowEmpty: false, message: 'is required' }};
    }

    const classes = useStyles();
    const [formState, hasError, handleChange, updateData,setFormState] = useFromValidator(schema);
    const [showFarm, setShowFarm] = useState(false)
    const [showShed, setShowShed] = useState(false)
    const [showUnInvoicedQuantity, setShowUnInvoicedQuantity] = useState(false)
    const [materialUnit, setMaterialUnit] = useState("")
    const [unitID, setUnitID] = useState(0)
    const [showMaterialNames, setShowMaterialNames] = useState(false)
    const [farmStorage,setFarmStorage] = useState(false)
    const history = useHistory();

    // taking the index of EGGS material type
    const materialTypeIndexOfEggs = materialTypes.findIndex(materialType => materialType.id === MaterialTypeId['EGGS']);

    //const [weightType,setweightType]= useState(0)
    // const [sheds,setSheds]= useState([])
    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            if (editMode && data.inventoryType === 2) {
                if(data.materialTypeId === 6){
                    setShowUnInvoicedQuantity(true)
                }
                setShowFarm(true)
                setShowShed(true)
            } else {
                setShowUnInvoicedQuantity(false)
                setShowFarm(false)
                setShowShed(false)
            }
            if (editMode) {
                onGetFarmStorage(data.farmId)
                if(data) {
                    if (data.farmIndex !== undefined && data.shedIndex !== undefined && data.shedIndex !== -1) {
                        onGetUnInvoicedQuantity(farms[data.farmIndex]?.id, sheds[data.shedIndex]?.id, data.isBrokenEggsEnabled, data.materialName,data.date,data?.saleID,data?.id);
                    }
                }
                if(user.versionID === version.pro){
                    updateData({...formState.values,...data}, editMode);
                }
                else{
                    updateData(data, editMode);
                }
                //in Edit material
                setShowMaterialNames(true)
                setMaterialUnit(data.materialUnit)
                setUnitID(data.unitID)
            } else {
                //in New material
                setShowUnInvoicedQuantity(false)
                setShowMaterialNames(true)
            }
            if (lineSaleCheck) {
                if(data) {
                    if (data.farmIndex !== undefined && data.shedIndex !== undefined && data.shedIndex !== -1) {
                        onGetUnInvoicedQuantity(farms[data.farmIndex]?.id, sheds[data.shedIndex]?.id, data.isBrokenEggsEnabled, data.materialName,data.date);
                    }
                }
                setShowMaterialNames(false)
                setFormState({...formState,
                    values:{
                        ...data,     
                        materialTypeIndex:5,
                    }
                })
                onGetMaterialNames(6)
                setShowMaterialNames(true)
            }
        }
    };

    useEffect(resetBeforeEdit, [reload]);

    /*useEffect(() => {
        console.debug(unInvoicedQuantity," unInvoicedQuantity",formState.values.materialIndex)
        if (unInvoicedQuantity !== undefined) {
            setShowUnInvoicedQuantity(true)
        } else {
            setShowUnInvoicedQuantity(false)
        }
    }, [unInvoicedQuantity]);*/

    useEffect(() => {
        if (lineSaleCheck && showMaterialNames) {
            //if linesale set material type to EGGS ie index 5
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    materialTypeIndex:5,
                }
            })
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMaterialNames,lineSaleCheck])

    useEffect(() => {
        if (!editMode) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    shedIndex: showShed ? '' : -1,
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showShed])

    useEffect(() => {
        if (!editMode) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    farmIndex: showFarm ? '' : -1,
                    isStorageUnit: false
                }
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFarm])



    useEffect(() => {
        if (formState.values.inventoryType === 1) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    farmIndex: showFarm ? '' : -1,
                    shedIndex: showShed ? '' : -1
                }
            })            
        }

        if (editMode === true && formState.values.inventoryType === 2 && formState.values.farmIndex === "") {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    shedIndex: ''
                }
            })
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.values.inventoryType])


    const onSubmit = () => {

        var id;
        var name;
        var unit;
        var unitId;
        
        if(!MaterialFunc.isValidMaterial(materialTypes,formState.values.materialTypeIndex)){
            return false;
        }
        
        if (formState.values.materialIndex === -1) {
            id = -1
            name = "Broken Eggs"
            unit = "Trays"
            unitId = 1
            
        } else {
            id = materialNames[formState.values.materialIndex].id
            name = materialNames[formState.values.materialIndex].materialName
            unit = materialNames[formState.values.materialIndex].materialUnit
            unitId = materialNames[formState.values.materialIndex].unitID
        }

        let tempData = {
            ...formState.values,
            materialTypeId: materialTypes[formState.values.materialTypeIndex] !== undefined 
            && materialTypes[formState.values.materialTypeIndex] !== null ? materialTypes[formState.values.materialTypeIndex].id : -1,
            materialId: id,
            materialName: name,
            eggTypeName: name,
            isBrokenEggsEnabled: name === "DEFAULT BROKENEGGS"  ? true : formState.values.isBrokenEggsEnabled,
            materialUnit: unit,
            unitID: unitId,
            isStorageUnit:formState.values.isStorageUnit ? 1 : 0,
            storageStock:getFarmStorageType()
        };
        if(user.versionID === version.proPlus){
            tempData = {
                ...tempData,
                taxProfileID : formState.values.gstTaxProfileIndex !== undefined &&
                formState.values.gstTaxProfileIndex !== null &&
                gstTaxProfiles[formState.values.gstTaxProfileIndex] !== undefined &&
                gstTaxProfiles[formState.values.gstTaxProfileIndex] !== null
                  ? gstTaxProfiles[formState.values.gstTaxProfileIndex].id
                  : null,
                gst:
                  formState.values.gstTaxProfileIndex !== undefined &&
                  formState.values.gstTaxProfileIndex !== null &&
                  gstTaxProfiles[formState.values.gstTaxProfileIndex] !== undefined &&
                  gstTaxProfiles[formState.values.gstTaxProfileIndex] !== null
                    ? gstTaxProfiles[formState.values.gstTaxProfileIndex].taxPercentage
                    : null,
            };
        }
        if (tempData.inventoryType === 2) {
            if (tempData.isStorageUnit) {
                tempData = {
                    ...tempData,
                    farmId: farms[tempData.farmIndex].id,
                    farmName: {
                        String: farms[tempData.farmIndex].name
                    }, shedId: 0,
                    shedName: {
                        String: "",
                    },
                    
                }

            } else {

                tempData = {
                    ...tempData,
                    farmId: farms[tempData.farmIndex].id,
                    farmName: {
                        String: farms[tempData.farmIndex].name
                    }, shedId: sheds[tempData.shedIndex].id,
                    shedName: {
                        String: sheds[tempData.shedIndex].name,
                    }
                }
            }
        }
        tempData = {
            ...tempData,
            billValue: calculateBillValue()
        }

        if (unitID === 1) {
            tempData = {
                ...tempData,
                costPerUnit: tempData.costPerUnit,
                billValue: calculateBillValue()
            }
        }

        if(user.versionID !== version.pro){
            tempData['discountPerUnit'] = getDiscountPerUnit();
            if (unitID === 1) {
                tempData = {
                    ...tempData,
                    discountPerUnit: getDiscountPerUnit()
                };
            }
        }

        if(user.versionID === version.pro){
            let formData;
            if(editMode){
                formData = {
                  ...data,
                  date : formState.values.date,
                  saleMaterials : []
                };
              }
              else{
                formData = {
                  date : formState.values.date,
                  saleMaterials : []
                };
              }

              formData.saleMaterials.push(tempData);
              if(editMode){
                formData['id'] = data.saleID;
                formData.saleMaterials[0].id = data.sID; //Only one material per array in pro version = temp material id
              }
              formData['totalBillValue'] = formData.saleMaterials[0].billValue;
              onSave(formData);
        }
        else{
            if (editMode) {
                onEdit(tempData);    
            }
            else{
                onSave({
                    ...tempData,
                    id: materialIndex - 1
                });
            }
        }
    };

    const getDiscountPerUnit = () => {
        if ((formState.values.discountPerUnit === undefined)
            || (formState.values.discountPerUnit !== 0
                && formState.values.discountPerUnit === "")) {
            return 0
        }
        else {
            return formState.values.discountPerUnit
        }
    };



    const getFarmStorageType = () => {
        if (formState.values.materialIndex !== undefined) {
            let farmStock = 0
            let MaterialIndex = formState.values.materialIndex
            let materialName = materialNames[MaterialIndex]?.materialName

            let storageStock = farmStorageStock?.filter(farmStock => farmStock["materialName"] === materialName )
            storageStock.map((stock) => {
                return farmStock += stock.availableStock
            })
            if(editMode) {
                farmStock = farmStock + formState.values.quantity
            }
            return farmStock
        }
        else {
            return 0
        }
    };



    /*const handleChangeInventoryType = (event) => {
        if (event.target.value === 2) {
            setShowFarm(true)
        } else {
            setShowFarm(false)
            setShowShed(false)
        }
    }*/

    const handleChangeFarm = (event) => {
        onGetShed(farms[event.target.value].id)
        onGetFarmStorage(farms[event.target.value].id)
        setFarmStorage(true)
        setShowShed(true)
    }

    const handleStorageCheckboxChange = (event) => {
        let storageCheckedVal = event.target.checked
        setFarmStorage(storageCheckedVal)
        // setShowShed(!storageCheckedVal)

        if (storageCheckedVal) {
            schema = {
                ...schema,
                shedIndex: presenceFalse,
            }
        } else {
            schema = {
                ...schema,
                shedIndex: presenceTrue,
                isStorageUnit: presenceFalse,
            }
        }

        delete formState.values.shedId
        delete formState.values.shedIndex
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                isStorageUnit: storageCheckedVal,
            }
        })

    }
    const handleChangeShed = (event) => {
        let materialTypeIndex = formState.values.materialTypeIndex
        
        if ((materialTypeIndex && materialTypes[materialTypeIndex].id === 6) || lineSaleCheck) {
            if (formState.values.materialIndex !== -1 ) {
                onGetUnInvoicedQuantity(farms[formState.values.farmIndex].id, sheds[event.target.value].id, formState.values.isBrokenEggsEnabled, materialNames[formState.values.materialIndex].materialName,formState.values.date)
            } else {
                onGetUnInvoicedQuantity(farms[formState.values.farmIndex].id, sheds[event.target.value].id, formState.values.isBrokenEggsEnabled, "Broken Eggs",formState.values.date)
            }
            
            setShowUnInvoicedQuantity(true)
        }
    }

    // const handleChangeBrokenEggs = (event) => {
    //     setFormState({...formState,
    //         values:{
    //             ...formState.values,
    //             isBrokenEggsEnabled: event.target.checked,
    //             materialIndex: -1,
    //             inventoryType: 2,
    //         }
    //     })
    //     if (formState.values.farmIndex !== undefined && formState.values.shedIndex !== undefined) {
    //         onGetUnInvoicedQuantity(farms[formState.values.farmIndex].id, sheds[formState.values.shedIndex].id, event.target.checked, "Broken Eggs",formState.values.date)
    //     }
    //     setShowFarm(true)
        
    // }

   const getDailyEggRate=(materialIdx, eggDetail) =>{
       const response= api.post('eggDetails',{...eggDetail});
       response.then(res=>{
            setFormState({
                ...formState,
                values : {
                    ...formState.values,
                    costPerUnit :res.rate,
                    materialIndex: materialIdx,
                    unitID: materialNames[materialIdx].unitID,
                    inventoryType: materialNames[materialIdx].inventoryType,

                }})
       }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('EggDetail not added');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
   }

    const handleChangeMaterialType =(event)=>{
        setFarmStorage(false)
        handlechangeStorageStock()
       //lineSaleCheck?
        setFormState({...formState,
                        values:{
                            ...formState.values,
                            materialIndex:'',
                            materialTypeIndex:event.target.value,
                            isStorageUnit: false,
                            freeEggs: event.target.value === materialTypeIndexOfEggs ? (formState.values.freeEggs || 0) : 0, // Reset free eggs
                        }})
        onGetMaterialNames(materialTypes[event.target.value].id)
        setShowMaterialNames(true)
       
    }

    const handleMaterialChange = (event) => {
        setMaterialUnit(materialNames[event.target.value].materialUnit)
        setUnitID(materialNames[event.target.value].unitID)
        if (MaterialFunc.isValidMaterial(materialTypes,formState.values.materialTypeIndex) && materialTypes[formState.values.materialTypeIndex].id === 6
            && formState.values.farmIndex !== undefined && farms.length > 0 
            && formState.values.shedIndex !== undefined && sheds.length > 0) {
            //removing this API call for now
            //onGetUnInvoicedQuantity(farms[formState.values.farmIndex].id, sheds[formState.values.shedIndex].id, formState.values.isBrokenEggsEnabled, materialNames[event.target.value].materialName)
            setShowUnInvoicedQuantity(true)
        } else {
            setShowUnInvoicedQuantity(false)
        }
        if(MaterialFunc.isValidMaterial(materialTypes,formState.values.materialTypeIndex) && 
            materialTypes[formState.values.materialTypeIndex].id === 6 && 
            eggDetail!==undefined && eggDetail.eggDate !== null){
            eggDetail={
                ...eggDetail,
                eggID: materialNames[event.target.value].id
            }
            getDailyEggRate(event.target.value, eggDetail)
        }  
        else{
            setFormState({...formState,
                values:{
                    ...formState.values,
                    unitID: materialNames[event.target.value].unitID,
                    materialIndex:event.target.value,
                    inventoryType: materialNames[event.target.value].inventoryType,
                }}
            )
        } 
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                farmIndex: (formState.values.farmIndex) ? formState.values.farmIndex : '',
                unitID: materialNames[event.target.value].unitID,
                inventoryType: materialNames[event.target.value].inventoryType,
                materialIndex: event.target.value
            }
        })

        if (materialNames[event.target.value].inventoryType === 2) {
            console.debug("in handle farm", event)
            setShowFarm(true)
        } else {
            setShowFarm(false)
            setShowShed(false)
        }         
    }

  

    const calculateBillValue = () => {

        let materialcommissionPerUnit = 0.0
        if (formState.values.commissionPerUnit > 0) {
            materialcommissionPerUnit = formState.values.commissionPerUnit
          }
        const { unitID, discountPerUnit: discountValue, materialTypeIndex, freeEggs, costPerUnit, quantity } = formState.values;
        const isTray = unitID === 1;
        const hasDiscount = discountValue > 0;
        const hasFreeEggs = materialTypeIndex === materialTypeIndexOfEggs && freeEggs > 0;
        const multiplier = isTray ? numberOfEggsInTray : 1;
        const totalQuantity = quantity * multiplier;
        const discountPerUnit = hasDiscount ? discountValue : 0;
        const freeEggsQuantity = hasFreeEggs ? freeEggs : 0;
        const billableQuantity = totalQuantity - freeEggsQuantity;
    
        let billValue = billableQuantity * ((costPerUnit+materialcommissionPerUnit) - discountPerUnit);
    
        if (isNaN(billValue)) {
            return 0;
        }
        return parseFloat(billValue.toFixed(2));
    };



    return <Dialog disableBackdropClick maxWidth='sm' open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" >{editMode ? 'Update' : 'New'} Material
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent  >
          {
            (user.versionID === version.pro) &&
            <>
            <div id="error">
                 {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className={classes.datePicker}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider> 
            </>
          }

          { !lineSaleCheck &&
            <FormControl className={classes.formControl}>
                <InputLabel id="farm-select-outlined-label">
                    Material Type
                </InputLabel>
                <Select
                    id="materialTypeid-select"
                    value={formState.values.materialTypeIndex !== undefined && formState.values.materialTypeIndex !== -1 ? formState.values.materialTypeIndex : ''}
                    onChange={event => { handleChange(event, handleChangeMaterialType) }}
                    name="materialTypeIndex"
                >
                    {materialTypes.map((materialType, index) =>
                        <MenuItem key={materialType.id} value={index}>{materialType.materialType}</MenuItem>
                    )}
                </Select> 
                    <Link
                        color="inherit"
                        href="#"
                        onClick={() =>{return  history.push("/material_master")}}
                        className={classes.link}
                        style={{color:"#02a6c7"}}
                        > Add new Material</Link>
                </FormControl>
            }
            {(showMaterialNames && materialNames !== undefined  ) &&
                <FormControl className={classes.formControl}>
                    <InputLabel id="materialName-select-label">Material Name</InputLabel>
                    <Select
                        id="materialName-select"
                        value={formState.values.materialIndex !== undefined 
                            && !isNaN(formState.values.materialIndex) ? formState.values.materialIndex : ''}
                        onChange={event =>{handleChange(event,handleMaterialChange)}}
                        name="materialIndex"
                    >
                        {materialNames.map((material, index) =>
                            <MenuItem key={material.id} value={index}>{material.materialName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {showFarm  &&
                <FormControl className={classes.formControl}>
                    <InputLabel id="farm-select-outlined-label">
                        Farm
                </InputLabel>
                    <Select
                        id="farm"
                        value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                        onChange={event => { handleChange(event, handleChangeFarm) }}
                        name="farmIndex"
                    >
                        {farms.map((farm, index) =>
                            <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {(showShed && sheds !== undefined) &&
                <FormControl className={classes.formControl}>
                    <InputLabel id="shed-select-outlined-label">
                        Shed
                </InputLabel>
                    <Select
                        id="shed"
                        value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                        onChange={event => { handleChange(event, handleChangeShed) }}
                        name="shedIndex"
                        disabled={formState.values.isStorageUnit  ? true : false }
                    >
                        {sheds.map((shed, index) =>
                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {((farmStorageStock.length > 0 ) && showShed)
               && <FormControl className={classes.formControl}>                        
               <FormControlLabel
                   control={<Checkbox checked={formState.values.isStorageUnit !== undefined ? formState.values.isStorageUnit : false} name="storage" />}
                   label={`Available Storage: ${getFarmStorageType()}`}
                   name="storage"
                   value={formState.values.isStorageUnit !== undefined ? formState.values.isStorageUnit : false}
                   onChange={event=>{
                       handleChange(event, handleStorageCheckboxChange)
                   }}
               />

               {
               formState.values.isStorageUnit !== undefined && formState.values.isStorageUnit ? 
                  <p
                  color="inherit"
                  className={classes.link}
                  style={{ color: "#02a6c7" }}
              > Available Eggs from Farm Storage</p>
               
               :""}
                  
           </FormControl>
            }
            {/* Commented to remove the broken eggs switch
             {formState.values.materialTypeIndex === 5 &&
                <FormControlLabel
                    className={classes.brokenEggsSwitch}
                    control={
                        <Switch
                        checked={formState.values.isBrokenEggsEnabled !== undefined ? formState.values.isBrokenEggsEnabled : false }
                        value={formState.values.isBrokenEggsEnabled}
                        // onChange={handleChange}
                        onChange={event => { handleChange(event, handleChangeBrokenEggs) }}
                        name="isBrokenEggsEnabled"
                        color="primary"
                        />
                    }
                    label="Broken Eggs"
                />
            } */}
            {showUnInvoicedQuantity &&
                <Typography className={classes.typo}>
                    {unInvoicedQuantity > 0 ?
                        <span>UnInvoiced Quantity = {unInvoicedQuantity} </span>
                        :
                        <span>No Daily Entry is Present</span>
                    }
                </Typography>
            }
            {MaterialFunc.isValidMaterial(materialTypes,formState.values.materialTypeIndex) && materialTypes[formState.values.materialTypeIndex].id === 7 ? 
                <TextField
                    className={classes.textField}
                    error={hasError('numberOfBirds')}
                    fullWidth
                    helperText={
                        hasError('numberOfBirds') ? formState.errors.numberOfBirds[0] : null
                    }
                    label={"Number Of Birds"}
                    name="numberOfBirds"
                    onChange = {handleChange}

                    type="number"
                    value={formState.values.numberOfBirds !== undefined ? formState.values.numberOfBirds : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
                : ""}
                <TextField
                    className={classes.textField}
                    error={hasError('quantity') ? hasError('quantity') : farmStorage && (farmStorageStock.length > 1 ) 
                        && (formState.values.quantity > getFarmStorageType())
                        ? true : false}
                    fullWidth
                    helperText={
                        hasError('quantity') ? formState.errors.quantity[0] : farmStorage && (farmStorageStock.length > 1 ) 
                        && (formState.values.quantity >= getFarmStorageType()) ? "Should Be Lesser than Storage Stock" : null
                    }

                    label={materialUnit !== "" ? "Quantity (In " + materialUnit + ")" : "Quantity"}
                    name="quantity"
                    onChange = {handleChange}
    
                    type="number"
                    value={formState.values.quantity !== undefined ? formState.values.quantity : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
            <TextField
                className={classes.textField}
                error={hasError('costPerUnit')}
                fullWidth
                helperText={
                    hasError('costPerUnit') ? formState.errors.costPerUnit[0] : null
                }
                label="Sale Price"
                name="costPerUnit"
                onChange = {handleChange}

                type="number"
                value={formState.values.costPerUnit !== undefined ? formState.values.costPerUnit : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {
                isCommissionSale ? 

                <TextField
                   className={classes.textField}
                   error={hasError('commissionPerUnit')}
                   fullWidth
                   helperText={
                       hasError('commissionPerUnit') ? formState.errors.commissionPerUnit[0] : null
                   }
                   label="Commission Per Unit"
                   name="commissionPerUnit"
                   onChange = {handleChange}
   
                   type="number"
                   defaultValue={0}
                   value={
                     formState.values.commissionPerUnit !== undefined
                       ? formState.values.commissionPerUnit
                       : 0
                   }
                   variant="outlined"
                   style={{backgroundColor:"#ffffff"}}
               /> :""
            }
            {
                (user.versionID !== version.pro) &&
                <TextField
                className={classes.textField}
                error={hasError('discountPerUnit')}
                fullWidth
                helperText={
                    hasError('discountPerUnit') ? formState.errors.discountPerUnit[0] : null
                }
                label="Discount Per Unit"
                name="discountPerUnit"
                onChange = {handleChange}
                type="number"
                value={formState.values.discountPerUnit !== undefined ? formState.values.discountPerUnit : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /> 
            }
            {formState.values.materialTypeIndex === materialTypeIndexOfEggs &&
                <TextField
                    className={classes.textField}
                    error={hasError('freeEggs')}
                    fullWidth
                    helperText={
                        hasError('freeEggs') ? formState.errors.freeEggs[0] : null
                    }
                    label="Free Eggs"
                    name="freeEggs"
                    onChange = {handleChange}
                    type="number"
                    value={formState.values.freeEggs !== undefined ? formState.values.freeEggs : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                /> 
            }
            <Typography
                className={classes.billValue}
                variant="body1"
            >
               Bill Value: <span>{calculateBillValue()}</span>
            </Typography>
            
            {
            (user.versionID === version.proPlus) && 
            <FormControl className={classes.formControl}>
            <InputLabel id="gst-select-outlined-label">GST</InputLabel>
            <Select
              id="gstTaxProfile-select"
              value={
                formState.values.gstTaxProfileIndex !== undefined && formState.values.gstTaxProfileIndex !== -1
                  ? formState.values.gstTaxProfileIndex
                  : ""
              }
              onChange={handleChange}
              name="gstTaxProfileIndex"
            >
              {gstTaxProfiles?.map((gstTaxProfile, index) => (
                <MenuItem key={gstTaxProfile.id} value={index}>
                  {gstTaxProfile.taxProfileName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          }
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};


export default Material;