import React,{useState, useEffect} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import { MaterialTable } from '../../../components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import {useGlobalState} from '../../../state';
import Icon from "@material-ui/core/Icon";
import PaymentIcon from "@material-ui/icons/Payment";

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.primary.default,
    },
    totalTable: {
        float: 'right',
        width: theme.spacing(28.6)
    },
    totalCell: {
        width: theme.spacing(14.5),
        paddingLeft: theme.spacing(0)
    },
    center: {
    marginRight: theme.spacing(1),
    textAlign: "center",
  },
}));

const PurchaseDetails = ({ data, handlePurchaseDetailsDialogClose, showPurchaseDetailsDialog,onPaymentEdit,
    onPurchaseEdit, onDelete }) => {
    const classes = useStyles();
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
      const [billValue, setBillValue] = useState(0);
      const [tdsValue, setTdsValue] = useState(0)
      const [tcsValue, setTcsValue] = useState(0)
      const [gstValue, setGstValue] = useState(0)
      const [finalTotal, setFinalTotal] = useState(0)

    /* const [billValueSum, setBillValueSum] = useState(0.0)
 
     function calculateBillTotal() {
         let total = 0.0
         let billValue = 0.0
         if(data !== undefined && data !== null && data.purchaseMaterials !== undefined && data.purchaseMaterials !== null){
             data.purchaseMaterials.forEach((purchaseMaterial,index)=>{
                 if (purchaseMaterial.weightType === 1){
                     billValue = (purchaseMaterial.billWeight*purchaseMaterial.costPerUnit)+(purchaseMaterial.billWeight*purchaseMaterial.costPerUnit*(data.tax/100))
                 }else{
                     billValue = (purchaseMaterial.deliveryWeight*purchaseMaterial.costPerUnit)+(purchaseMaterial.deliveryWeight*purchaseMaterial.costPerUnit*(data.tax/100))
                 }
 
                 total = total + billValue
             })
         }
         setBillValueSum(total)
     }
 
     useEffect(calculateBillTotal, [data]);
 */

     useEffect(() => {
        if (data && data.purchaseMaterials && Array.isArray(data.purchaseMaterials) && (data.purchaseMaterials).length > 0) {
            calculateValues(data)
        }
     }, [data])

    function calculateValues(data){
        let billAmt = 0
        let tdsAmt = 0
        let tcsAmt = 0
        let gstAmt = 0
        let purchaseMaterials = data.purchaseMaterials 
        if(purchaseMaterials !== undefined){
            purchaseMaterials.forEach((purchaseMaterial) => {
                billAmt += purchaseMaterial.billValue
                let  commissionAmount  = 0
                if (purchaseMaterial.commissionPerUnit > 0) {
                    if (purchaseMaterial.weightType === 1) {
                        commissionAmount = (purchaseMaterial.billWeight * (purchaseMaterial.commissionPerUnit));
                    } else {
                        commissionAmount = (purchaseMaterial.deliveryWeight *  ( purchaseMaterial.commissionPerUnit));
                    }
                }
                let taxableTotal  = purchaseMaterial.billValue - commissionAmount
                let tdsTaxAmt = taxableTotal * ((data.tds ? data.tds : 0)) / 100
                let tcsTaxAmt = taxableTotal * ((data.tcs ? data.tcs : 0)) / 100
                let gstTaxAmt = taxableTotal * ((purchaseMaterial.gst ? purchaseMaterial.gst : 0)) / 100
                tdsAmt += tdsTaxAmt
                tcsAmt += tcsTaxAmt
                gstAmt += gstTaxAmt
            })
        }
        let gstTCSVal = gstAmt * (data.tcs/100)
        let totalTCS = Math.ceil(tcsAmt + gstTCSVal)
        let total = billAmt - tdsAmt + totalTCS + gstAmt
        setBillValue(billAmt)
        setTdsValue(tdsAmt)
        setTcsValue(totalTCS)
        setGstValue(gstAmt)
        setFinalTotal(total)
    }

    return data ? <Dialog maxWidth='md' open={showPurchaseDetailsDialog} onClose={handlePurchaseDetailsDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title">Purchase Details
        {handlePurchaseDetailsDialogClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={handlePurchaseDetailsDialogClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
        <DialogContent dividers  >
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
            >
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {data.billNumber}
                    </Typography>
                    <Typography color="textSecondary">
                        Bill Number
                        </Typography>
                </Grid>
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {moment(data.date).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography color="textSecondary">
                        Date
                        </Typography>
                </Grid>
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {data.truckNumber}
                    </Typography>
                    <Typography color="textSecondary">
                        Truck Number
                        </Typography>
                </Grid>
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {data.tax}
                    </Typography>
                    <Typography color="textSecondary">
                        Tax(%)
                        </Typography>
                </Grid>
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {data.tds}
                    </Typography>
                    <Typography color="textSecondary">
                        TDS(%)
                        </Typography>
                </Grid>
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {data.paymentDueDate}
                    </Typography>
                    <Typography color="textSecondary">
                        Payment Due (in Days)
                        </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                justify="flex-start"
                alignItems="center"
                style={{
                    marginBottom: "0.5rem",
                }}
                >
                <div className={classes.center} 
                    style={{
                        display: (user.role === "STAFF" && !moment(data.createTime).isSame(moment(), "date") ? "none" : "block")
                    }}>
                    <IconButton
                    onClick={() => onPaymentEdit(data)}
                    aria-label="Delete"
                    className={classes.margin}
                    >
                    <PaymentIcon color="error">payment</PaymentIcon>
                    </IconButton>
                    <Typography color="textSecondary">Payment edit</Typography>
                </div>
                <div className={classes.center}
                    style={{
                        display: (user.role === "STAFF" && !moment(data.createTime).isSame(moment(), "date") ? "none" : "block")
                    }}>
                    <IconButton
                    onClick={() => onPurchaseEdit(data)}
                    aria-label="Delete"
                    className={classes.margin}
                    >
                    <Icon color="primary">edit</Icon>
                    </IconButton>
                    <Typography color="textSecondary">Edit</Typography>
                </div>
                <div className={classes.center}
                    style={{
                        display: (user.role === "STAFF" && !moment(data.createTime).isSame(moment(), "date") ? "none" : "block")
                    }}>
                    <IconButton
                    onClick={() => onDelete(data)}
                    aria-label="Delete"
                    className={classes.margin}
                    >
                    <Icon color="error">delete</Icon>
                    </IconButton>
                    <Typography color="textSecondary">Delete</Typography>
                </div>
                </Grid>
            <Grid>
                <MaterialTable
                    columns={[
                        { title: 'id', field: 'id', hidden: true },
                        { title: 'Item Name', field: 'materialName', editable: 'never' },
                        {
                            title: 'Weight Type', field: 'weightType', editable: 'never',
                            render: (rowData) => {
                                return <span>{rowData.weightType === 1 ? 'Bill Weight' : 'Delivery Weight'}</span>
                            }
                        },
                        {
                            title: 'Inventory Type', field: 'inventoryType', editable: 'never',
                            render: (rowData) => {
                                return <span>{rowData.inventoryType === 1 ? 'Feed Mill' : (rowData.farmName.String + ' (' + rowData.shedName.String + ')')}</span>
                            }
                        },
                        { title: 'Bill Weight', field: 'billWeight', editable: 'never' },
                        { title: 'Delivery Weight', field: 'deliveryWeight', editable: 'never' },
                        { title: 'Cost Per Unit', field: 'costPerUnit', editable: 'never' },
                        { title: 'Commission Per Unit', field: 'commissionPerUnit', editable: 'never' },
                        {
                            title: 'Bill Value', field: 'billValue', editable: 'never',
                            render: (rowData) => {
                                let billValue = 0;

                                let taxValue = 1;
                                //If there is no Tax then multiply by 1
                                let materialCommissionPerUnit = 0
                                if ( rowData.commissionPerUnit > 0) {
                                    materialCommissionPerUnit = rowData.commissionPerUnit
                                }
                                // If the Tax % is greater than 0, then calculate tax in decimals
                                if (data.tax < 0) {
                                    taxValue = (1 + (data.tax / 100));
                                }

                                if (rowData.weightType === 1) {
                                    billValue = (rowData.billWeight *  (rowData.costPerUnit +materialCommissionPerUnit)) * taxValue;
                                } else {
                                    billValue = (rowData.deliveryWeight * (rowData.costPerUnit + materialCommissionPerUnit)) * taxValue;
                                }
                                console.log('billValue', billValue);

                                return <span>{/*/*
                                    data.tax !== undefined ?
                                        (rowData.weightType === 1 ?
                                            ((rowData.billWeight * rowData.costPerUnit) + (rowData.billWeight * rowData.costPerUnit * (data.tax / 100)))
                                            :
                                            ((rowData.deliveryWeight * rowData.costPerUnit) + (rowData.deliveryWeight * rowData.costPerUnit * (data.tax / 100))))
                                        :
                                        ''
                                        */}{
                                        billValue.toLocaleString(user.locales,amountObj)
                                    }
                                </span>
                            },
                        }
                    ]}
                    data={data.purchaseMaterials || []}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        showTitle: false,
                        
                        toolbar: false,
                        paging: false
                    }}
                />
                <Table className={classes.totalTable} >
                    <TableHead>
                    <TableRow>
                        <TableCell className={classes.totalCell}>Bill Value</TableCell>
                        <TableCell>{(billValue).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    {/* commenting for now */}
                    {gstValue && gstValue > 0 ? 
                        <TableRow>
                        <TableCell className={classes.totalCell}>GST</TableCell>
                        <TableCell>{(gstValue).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    : null}
                    {tdsValue && tdsValue > 0 ? <TableRow>
                        <TableCell className={classes.totalCell}>TDS </TableCell>
                        <TableCell>{(tdsValue).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow> : null}
                    {tcsValue && tcsValue > 0 ? <TableRow>
                        <TableCell className={classes.totalCell}  >TCS </TableCell>
                        <TableCell>{(tcsValue).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>: null}
                    
                    <TableRow>
                        <TableCell className={classes.totalCell} >Final Total </TableCell>
                        <TableCell>{(finalTotal).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    {/* commenting for now */}
                    {/* <TableRow>
                        <TableCell className={classes.totalCell}  >Total with TDS </TableCell>
                        <TableCell>                        
                        {
                        data.totalBillValue === 0 ?
                        (0).toLocaleString(user.locales,amountObj): 
                        (data.totalBillValue-(((data.tds || 0)*data.totalBillValue)/100)).toLocaleString(user.locales,amountObj)
                        }
                        </TableCell>
                    </TableRow> */}
                    </TableHead>
                </Table>
            </Grid>
        </DialogContent>
    </Dialog>
        : null
};

export default PurchaseDetails;