import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';


let schema = {
    primaryContact: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    companyName: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    type: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    
    taxProfileID: {
        presence: false,
  },
    isLineEnabled: {
        presence: false,
    },
    phoneNumber: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    emailAddress: {
        presence: false,
        email: true,
        length: {
            maximum: 64
        }
    },
    gstNumber: {
        presence: false,
        length: {
            maximum: 15
        }
    },
    openingBalance:{
        presence: { allowEmpty: false, message: 'is required'}
    },
    openingTraysBalance:{
        presence: { allowEmpty: false, message: 'is required'}
    },
    address: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formControlLabelClass: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const CustomerVendorDetail = ({deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, isLine, editMode = false, taxProfiles }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData, setFormState] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false);
    
    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (data !== undefined && data !== null) {
            if (data.gstNumber === "") {
                data = {
                    ...data,
                    gstNumber: null
                }
            }
            if (data.emailAddress === "") {
                data = {
                    ...data,
                    emailAddress: null
                }
            }
        }
        console.debug("data ", data)
        if (showDialog) {
            if (showDialog) {
                setPopupLoading(false);
                updateData(data, editMode);
            }
        };
    }
    useEffect(resetBeforeEdit, [showDialog]);

    useEffect(() =>{
        if(isLine === false ){
            delete schema.openingTraysBalance
        }else{
             schema.openingTraysBalance={
                presence:{ allowEmpty: false, message: 'is required'}
             }
        }
    },[showDialog, isLine])

    function handleMultiSelectTaxProfile(event) {
        setFormState({
            ...formState,
            values: {
              ...formState.values,
              taxProfileID:event.target.value
            },
          });
      }

      function handletypeChange(event) {
        setFormState({
            ...formState,
            values: {
              ...formState.values,
              type: event.target.value
            },
          });
      }
    const onSubmit = () => {
        setPopupLoading(true)
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        if (editMode)
            onEdit(formState.values, setPopupLoading);
        else
            onSave(formState.values, setPopupLoading);
    };

    const setNullForEmptyString = (event) => {
        if (event.target.value === '') {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [event.target.name]: null
                }
            })
        }
    };

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" >{editMode ? 'Update' : 'New'} Customer Vendor Detail
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <br/><br/>
                <TextField disabled
                className={classes.textField}
                error={hasError('primaryContact')}
                fullWidth
                helperText={
                    hasError('primaryContact') ? formState.errors.primaryContact[0] : null
                }
                label="Primary Contact"
                name="primaryContact"
                onChange={handleChange}
                type="text"
                value={formState.values.primaryContact !== undefined ? formState.values.primaryContact : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="type-select-outlined-label">
                    Type
                </InputLabel>
                <Select disabled
                    id="type"
                    value={formState.values.type !== undefined ? formState.values.type : ''}
                    onChange={handleChange}
                    name="type"
                >
                    <MenuItem key={0} value={1}>Customer</MenuItem>
                    <MenuItem key={1} value={2}>Vendor</MenuItem>
                </Select>
            </FormControl>
            <div hidden>
            <FormControlLabel disabled
                className={classes.formControlLabelClass}
                control={
                    <Switch
                    checked={isLine }
                        name="isLineEnabled"
                        color="primary"
                    />
                }
                label={formState.values.type === 1 ? "Line Customer": "Line Vendor"}
            /></div>
            <TextField disabled
                className={classes.textField}
                error={hasError('companyName')}
                fullWidth
                helperText={
                    hasError('companyName') ? formState.errors.companyName[0] : null
                }
                label="Company Name"
                name="companyName"
                onChange={handleChange}
                type="text"
                value={formState.values.companyName !== undefined ? formState.values.companyName : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('phoneNumber')}
                fullWidth
                helperText={
                    hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
                }
                label="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.phoneNumber !== undefined ? formState.values.phoneNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('address')}
                fullWidth
                multiline
                rows="3"
                helperText={
                    hasError('address') ? formState.errors.address[0] : null
                }
                label="Address"
                name="address"
                onChange={handleChange}
                type="text"
                value={formState.values.address || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('gstNumber')}
                fullWidth
                helperText={
                    hasError('gstNumber') ? formState.errors.gstNumber[0] : null
                }
                label="GST Number"
                name="gstNumber"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="text"
                value={formState.values.gstNumber || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('openingBalance')}
                fullWidth
                helperText={
                    hasError('openingBalance') ? formState.errors.openingBalance[0] : null
                }
                label="Opening Balance"
                name="openingBalance"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="number"
                value={formState.values.openingBalance || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {isLine === true ?
            <TextField disabled
                className={classes.textField}
                error={hasError('openingTraysBalance')}
                fullWidth
                helperText={
                    hasError('openingTraysBalance') ? formState.errors.openingTraysBalance[0] : null
                }
                label="Opening Trays Balance"
                name="openingTraysBalance"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="number"
                value={formState.values.openingTraysBalance || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /> : ""}
            <TextField disabled
                className={classes.textField}
                error={hasError('emailAddress')}
                fullWidth
                helperText={
                    hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                }
                label="Email Address"
                name="emailAddress"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="text"
                value={formState.values.emailAddress || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
            :
            <div>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <TextField
                className={classes.textField}
                error={hasError('primaryContact')}
                fullWidth
                helperText={
                    hasError('primaryContact') ? formState.errors.primaryContact[0] : null
                }
                label="Primary Contact"
                name="primaryContact"
                onChange={handleChange}
                type="text"
                value={formState.values.primaryContact !== undefined ? formState.values.primaryContact : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl className={classes.formControl}>
                <InputLabel id="type-select-outlined-label">
                    Type
                </InputLabel>
                <Select
                    id="type"
                    value={formState.values.type !== undefined && formState.values.type !== null ? formState.values.type : ''}
                    onChange={handletypeChange}
                    name="type"
                >
                    <MenuItem key={0} value={1}>Customer</MenuItem>
                    <MenuItem key={1} value={2}>Vendor</MenuItem>
                    <MenuItem key={2} value={3}>Customer And Vendor</MenuItem>
                    <MenuItem key={2} value={4}>Commission Agent</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
                Tax Profiles
              </InputLabel>
              <Select
                id="taxProfileID"
                value={
                  formState.values?.taxProfileID !== undefined  &&  formState.values?.taxProfileID !== null &&
                  formState.values.taxProfileID !== 0 ?  formState.values.taxProfileID  
                    : ""
                }
                onChange={handleMultiSelectTaxProfile}
                name="taxProfileID"
              >
                {" "}
                {taxProfiles !== undefined &&
                taxProfiles !== null &&
                Array.isArray(taxProfiles) &&
                taxProfiles.length > 0
                  ? taxProfiles.map((taxProfile, idx) => {
                      return (
                        <MenuItem key={taxProfile.id} value={taxProfile.id}>
                          {taxProfile.taxProfileName}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
            <div hidden>
            <FormControlLabel
                className={classes.formControlLabelClass}
                control={
                    
                    <Switch
                        checked={isLine}
                        name="isLineEnabled"
                        color="primary"
                    />
                }
                label={formState.values.type === 1 ? "Line Customer": "Line Vendor"}
            />
            </div>
            <TextField
                className={classes.textField}
                error={hasError('companyName')}
                fullWidth
                helperText={
                    hasError('companyName') ? formState.errors.companyName[0] : null
                }
                label="Company Name"
                name="companyName"
                onChange={handleChange}
                type="text"
                value={formState.values.companyName !== undefined ? formState.values.companyName : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('phoneNumber')}
                fullWidth
                helperText={
                    hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
                }
                label="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.phoneNumber !== undefined ? formState.values.phoneNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('address')}
                fullWidth
                multiline
                rows="3"
                helperText={
                    hasError('address') ? formState.errors.address[0] : null
                }
                label="Address"
                name="address"
                onChange={handleChange}
                type="text"
                value={formState.values.address || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('gstNumber')}
                fullWidth
                helperText={
                    hasError('gstNumber') ? formState.errors.gstNumber[0] : null
                }
                label="GST Number"
                name="gstNumber"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="text"
                value={formState.values.gstNumber || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('openingBalance')}
                fullWidth
                helperText={
                    hasError('openingBalance') ? formState.errors.openingBalance[0] : null
                }
                label="Opening Balance"
                name="openingBalance"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="number"
                value={formState.values.openingBalance || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {isLine === true ?
            <TextField
                className={classes.textField}
                error={hasError('openingTraysBalance')}
                fullWidth
                helperText={
                    hasError('openingTraysBalance') ? formState.errors.openingTraysBalance[0] : null
                }
                label="Opening Trays Balance"
                name="openingTraysBalance"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="number"
                value={formState.values.openingTraysBalance || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /> : 
                ""
            }
            <TextField
                className={classes.textField}
                error={hasError('emailAddress')}
                fullWidth
                helperText={
                    hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                }
                label="Email Address"
                name="emailAddress"
                onChange={event => { handleChange(event, setNullForEmptyString) }}
                type="text"
                value={formState.values.emailAddress || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
          }
        </DialogContent>
    </Dialog>
};

export default CustomerVendorDetail;