import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import api from '../../common/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import ForgotPasswordDialog from './ForgotPassword';
import { useGlobalState } from '../../state';
import Hidden from '@material-ui/core/Hidden';

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/chick.webp)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    KnowMoreContainer:{
        marginTop:"10px"
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    knowMoreButton:{
        color:  theme.palette.primary.main,
        fontWeight:500
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    errorText: {
        color: theme.palette.error
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    socialButtons: {
        marginTop: theme.spacing(3)
    },
    socialIcon: {
        marginRight: theme.spacing(1)
    },
    sugestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    logo: {
        width: '36.985373171051904%',
        alignSelf: 'start',
        marginBottom: '20px'
    },
    logoContainer: {
        // backgroundColor: "rgba(0, 0, 0, 0.16)",
        backgroundColor: "#404040",
    },
}));

const SignIn = props => {



    const { history } = props;
    const [{ user }, dispatch] = useGlobalState();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [apiErrorText, setApiErrorText] = useState(undefined);
    const [apiMessageText, setApiMessageText] = useState(undefined);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const [showDialog, setShowDialog] = React.useState(false);

    function handleClickOpen(event) {

        event.preventDefault();
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
    }

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    //If the Use has a Valid Session, redirect
    const afterLogin = () => {
        if (user && user.isLoggedIn) {
            if (user.role === "DRIVER") {
                history.push('/line-sale-driver');
            } else if (user.role === "FEEDSUPER") {
                history.push('/daily-entry-feed');
            } else {
                history.push('/');
            }


        }

    };
    //Calls Login if the IsLogged State has changed.
    useEffect(afterLogin, [user]);

    const handleBack = () => {
        history.goBack();
    };

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSignIn = event => {
        setLoading(true);
        event.preventDefault();
        const response = api.post('sign-in', {
            "email": formState.values.email,
            "password": formState.values.password
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setApiErrorText('Wrong email or password');
                else
                    setApiErrorText(err.message)
            } else {
                setApiErrorText('Unknown Error')
            }
            setLoading(false);

        });

        response.then(res => {
            //Add Token to Storage
            if (res) {
                //Set User Profile
                dispatch({
                    type: 'setProfile',
                    user: res
                });

            }
        });

    };

    const handlePasswordReset = (email) => {
        setShowDialog(false);
        setLoading(true);
        setApiErrorText(undefined);
        setApiMessageText(undefined);

        const response = api.post('reset-password', {
            "email": email
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setApiErrorText('Email ID not registered with us');
                else
                    setApiErrorText(err.message)
            } else {
                setApiErrorText('Unknown Error')
            }
            setLoading(false);
        });

        response.then(res => {
            if (res) {
                setApiMessageText("Please check your mailbox")
            }
        });

        response.finally(() => {
            setLoading(false);
        })
    };

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);
    return (
        <React.Fragment>
            <ForgotPasswordDialog showDialog={showDialog} handleClose={handleClose}
                handlePasswordReset={handlePasswordReset} />
            <div className={classes.root}>
                <Grid
                    className={classes.grid}
                    container
                >
                    <Grid
                        className={classes.quoteContainer}
                        item
                        lg={5}
                    >
                        <div className={classes.quote}>
                        </div>
                    </Grid>
                    <Grid
                        className={classes.content}
                        item
                        lg={7}
                        xs={12}
                    >
                        <div className={classes.logoContainer}>
                            <Hidden lgUp>
                                    <img
                                        className={classes.logo}
                                        alt="Logo"
                                        src="/images/logos/poultry-client-mobile-view.png"
                                    />
                            </Hidden></div>
                        <div className={classes.content}>
                            <div className={classes.contentHeader}>
                                <IconButton onClick={handleBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            <div className={classes.contentBody}>
                                {
                                    loading ?
                                        <div className={classes.progressContainer}>
                                            <CircularProgress className={classes.progressBar} />
                                        </div> :
                                        <form
                                            className={classes.form}
                                            onSubmit={handleSignIn}
                                        >
                                            {
                                                apiErrorText ?
                                                    <Chip color="secondary" icon={<ErrorIcon />}
                                                        label={apiErrorText} /> :
                                                    undefined
                                            }
                                            {
                                                apiMessageText ?
                                                    <Chip color="primary" icon={<CheckCircleIcon />}
                                                        label={apiMessageText} /> :
                                                    undefined
                                            }
                                            <Typography
                                                className={classes.title}
                                                variant="h2"
                                            >
                                                Sign in
                                            </Typography>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                Login with email address
                                            </Typography>
                                            <Grid
                                                className={classes.socialButtons}
                                                container
                                                spacing={2}
                                            >
                                            </Grid>
                                            <TextField
                                                className={classes.textField}
                                                error={hasError('email')}
                                                fullWidth
                                                helperText={
                                                    hasError('email') ? formState.errors.email[0] : null
                                                }
                                                label="Email address"
                                                name="email"
                                                onChange={handleChange}
                                                type="text"
                                                value={formState.values.email || ''}
                                                variant="outlined"
                                                autoComplete='email'
                                            />
                                            <TextField
                                                className={classes.textField}
                                                error={hasError('password')}
                                                fullWidth
                                                helperText={
                                                    hasError('password') ? formState.errors.password[0] : null
                                                }
                                                autoComplete='current-password'
                                                label="Password"
                                                name="password"
                                                onChange={handleChange}
                                                type="password"
                                                value={formState.values.password || ''}
                                                variant="outlined"
                                            />
                                            <Button
                                                className={classes.signInButton}
                                                color="primary"
                                                disabled={!formState.isValid}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Sign in now
                                            </Button>
                                            <Grid container>
                                                <Grid item xs>
                                                    <Link
                                                        onClick={handleClickOpen}
                                                        variant="h6"
                                                        component="button"
                                                        color="secondary"
                                                    >
                                                        Forgot Password?
                                                    </Link>
                                                </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body1"
                                                        >
                                                            Don't have an account?{' '}
                                                            <Link
                                                                component={RouterLink}
                                                                to="/sign-up"
                                                                variant="h6"
                                                            >
                                                                Sign up
                                                            </Link>
                                                        </Typography>
                                                    </Grid> 

                                                   
                                                {/* <Hidden lgUp>

                                                    <Grid item className={classes.KnowMoreContainer}>
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body1"
                                                        >
                                                        To know more about PoultryProPlus, {"  "}
                                                        <a
                                                            href="https://www.poultryproplus.com/" target="_blank"
                                                            className={classes.knowMoreButton}
                                                             rel="noopener noreferrer" 
                                                        >
                                                            Click Here
                                                        </a>
                                                       
                                                    </Typography>
                                                </Grid>
                                                </Hidden> */}
                                            </Grid>
                                        </form>
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

SignIn.propTypes = {
    history: PropTypes.object
};

export default withRouter(SignIn);
