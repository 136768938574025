import React, {useEffect, useState,useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Typography from "@material-ui/core/Typography";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useReactToPrint } from 'react-to-print';

import api from '../../../common/api';
import FeedTable from './FeedTable';
import { Chip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { calculateEnergyData, formatEnergyProfileValue, getTotalQuantity, initialEnergyValues } from '../utils/nutritionCalculations';

const useStyles = makeStyles(theme => ({
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    downloadPdfContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%'
    },
    downloadPdfButton: {
        margin: theme.spacing(2),
    },
    errorChip:{
        marginLeft: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },
      reportContainer :{
        width:"100%",
      },
      formulationBox : {
        display:'grid',
        marginBottom:'5px'
      },
      energyProfileContainer: {
        '& h3':{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        }
      },
      tableFooter: {
        '& td': {
            padding: theme.spacing(2),
            fontWeight:'bold'
        },
      },
      grid: props =>({
        padding: props.isSmallScreen ? theme.spacing(1) : theme.spacing(2),
      }),
      buttonGridContainer: props =>({
        padding: props.isSmallScreen ? theme.spacing(1, 0) : theme.spacing(2, 0),
      }),
      red: {
        color: '#f44336',
        fontSize:'14px'
      },
      green:{
        color:'#4caf50',
        fontSize:'14px'
      },
}));

const AllFeedFormulation = ({ handleClose, showDialog,feedData, setFeedData, medicineData, setMedicineData,
     feedFormulation, currentTable, setCurrentTable, energies:materialNutritionMap, setUpdatedFeedFormulationData}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
        defaultMatches: true
    });
    const classes = useStyles({ isSmallScreen });

    const [materialTypes1, setMaterialTypes1] = useState([])
    const [materialTypes2, setMaterialTypes2] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false);
    const [energyValues, setEnergyValues] = useState(initialEnergyValues)

    useEffect(()=>{
        setEnergyValues(calculateEnergyData({data: feedData, energyValues: initialEnergyValues, materialNutritionMap}));
    }, [feedData, showDialog, materialNutritionMap])


    const handleCurrentTable = (table) => {
        // if user clicked the same button show both table
        if (table === currentTable){
            setCurrentTable('both');
            return
        }
        setCurrentTable(table);
    }
    

    // eslint-disable-next-line
    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });

    const getAllMaterialType = () => {
        setLoading(true);
        setErrorMessage('');
    
        const requests = [api.get(`material-type/1`), api.get(`material-type/2`)];
        Promise.all(requests)
            .then(([response1, response2]) => {
                setMaterialTypes1([...response1]);
                setMaterialTypes2([...response2]);
            })
            .catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        setErrorMessage('No Material Type');
                    else
                        setErrorMessage(err.message);
                } else {
                    setErrorMessage('Unknown Error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    useEffect(getAllMaterialType, []);

    const onErrorChipClose=()=>{
        setErrorMessage('');
    }

    const FormulationDetailsGrid = ({ title, value }) => {
        return (
            <Grid item>
                    <Typography variant="h5" component="h2">
                       {value}
                    </Typography>
                    <Typography color="textSecondary">
                        {title}
                    </Typography>
            </Grid>
        )
    }

    const FormulationDetailsHeader = () => {
        return (
            <Grid
                container
                justifyContent='space-between'
                alignItems='flex-end'
                spacing={2}
            >
                <FormulationDetailsGrid title={"Date"} value={feedFormulation['date']}/>
                <FormulationDetailsGrid title={"Farm / Storage"} value={feedFormulation.isStorage ? `${feedFormulation.feedStorageName} (Storage)` : feedFormulation.farmName}/>
                <FormulationDetailsGrid title={"Shed"} value={feedFormulation['shedName']}/>
                <FormulationDetailsGrid title={"Materials"} value={(feedData) ? feedData.length: 0}/>
                <FormulationDetailsGrid title={"Medicines"} value={(medicineData) ? medicineData.length: 0}/>
                <FormulationDetailsGrid title={"Status"} value={
                    <>
                        <FiberManualRecordIcon className={feedFormulation['isActive'] === 'Active' ? classes.green : classes.red} /> 
                        {feedFormulation['isActive']}
                    </>
                }/>
            </Grid>
        )
    }

    const EnergyProfileChip = ({ title, value }) => {
        let totalQuantity = getTotalQuantity(feedData) + getTotalQuantity(medicineData);
        return (
            <Grid item>
                <Chip 
                    size="medium"
                    color="primary"
                    label={`${title}: ${formatEnergyProfileValue(value, totalQuantity)}`} 
                />
            </Grid>
        )
    }

    const EnergyProfile = () => {
        return (
            <div  className={classes.energyProfileContainer}>
                <Typography variant="h3" component="h3">
                    Energy Profile
                </Typography>
                <Grid container spacing={2}>
                    {Object.keys(energyValues).map((key) => (
                        <EnergyProfileChip 
                            key={key} 
                            title={energyValues[key].name} 
                            value={energyValues[key].value}
                        />
                    ))}
                </Grid>
            </div>
        )
    }

    return (
    <>
    {
        (feedFormulation === undefined) ?
        <></>
        :
        <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth={true}>
        <DialogTitle id="form-dialog- title"> All Formulations
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
            {errorMessage && <Chip size="small" color="secondary" className={classes.errorChip} icon={<ErrorIcon/>} label={errorMessage} onDelete={onErrorChipClose}/>}
            <div id='report-container' className={classes.reportContainer} ref={componentRef} style={{padding:'15px'}}>
            <FormulationDetailsHeader />
            {["both", "material"].includes(currentTable) && <EnergyProfile />}
            <Grid container spacing={2} className={classes.buttonGridContainer}>
                <Grid item>
                    <Button variant={currentTable === "material" ? "contained" : "outlined"}
                     size="medium" color="primary" onClick={()=>handleCurrentTable("material")}>
                        Material
                    </Button>
                </Grid>
                <Grid item >
                    <Button variant={currentTable === "medicine" ? "contained" : "outlined"}
                     size="medium" color="secondary" onClick={()=>handleCurrentTable("medicine")}>
                        Medicine
                    </Button>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={4}>
                {["both", "material"].includes(currentTable) && 
                    <Grid item sm={12} md={currentTable === "both" ? 6 : 12}>
                        <FeedTable
                            key={'feed-material-table'}
                            title={'Materials'}
                            materialTypeColumnTitle={'Material Name'}
                            dataList={feedData || []} 
                            feedFormulationId={feedFormulation.id} 
                            materialTypes={materialTypes1} 
                            setErrorMessage={setErrorMessage} 
                            setDataList={setFeedData} 
                            tableFooterClass={classes.tableFooter} 
                            totalQuantity={getTotalQuantity(feedData)} 
                            tableEndpoint='feed-formulation-material'
                            isLoading={loading}
                            setUpdatedFeedFormulationData={setUpdatedFeedFormulationData}
                            materialNutritionMap={materialNutritionMap}
                        />
                    </Grid>
                }
                <br/>
                {["both", "medicine"].includes(currentTable) && 
                    <Grid item sm={12} md={currentTable === "both" ? 6 : 12}>
                        <FeedTable
                            key={'feed-medicine-table'}
                            title={'Medicines'}
                            materialTypeColumnTitle={'Medicines/Feed Supplement'}
                            dataList={medicineData || []} 
                            feedFormulationId={feedFormulation.id} 
                            materialTypes={materialTypes2} 
                            setErrorMessage={setErrorMessage} 
                            setDataList={setMedicineData} 
                            tableFooterClass={classes.tableFooter} 
                            totalQuantity={getTotalQuantity(medicineData)} 
                            tableEndpoint='feed-formulation-medicine'
                            isLoading={loading}
                            setUpdatedFeedFormulationData={setUpdatedFeedFormulationData}
                        />
                    </Grid>
                }
            </Grid>
            </div>
            <div className={classes.downloadPdfContainer}>
                <Button variant="outlined" color="primary" className={classes.downloadPdfButton} 
                    onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>
                    Download PDF
                </Button>
            </div>
          </DialogContent>
    </Dialog>
    }
    </>);
};

export default AllFeedFormulation;